import * as yup from "yup"

import i18n from "lib/i18n"
import XRegExp from "xregexp" // extended regex syntax, so we can match UTF-8 chars

// n.b. this regex _must_ be functionally equivalent to the server-side
// validation regex at lib/forms/validation.rb, because some server-side
// validation happens after payment has already been taken. So, if an email
// passes client-side validation, it must pass server-side validation
const emailRegex = XRegExp(
  "^[\\p{Letter}\\w+\\-.]*[\\p{Letter}\\w+\\-]@[a-z\\d-]+(\\.[a-z\\d-]+)*\\.[a-z]+$",
  "i",
)

/**
 * The definitions of validations for each possible field, given the context
 * @param maxAmount the maximum amount for the region
 * @param minAmount the minimum amount for the region
 * @return {{amount, cvv: *, firstName, cardNumber: *, email}} field names, and the
 *   yup validations associated with them
 */
const definitions = ({ maxAmount, minAmount }) => ({
  accountName: yup.string().required(i18n.t("donation.message.account_name_error")),
  address: yup.string().when("giftAid", {
    is: true,
    then: (schema) => schema.required(i18n.t("donation.message.address_error")),
  }),
  amount: yup
    .number()
    .required(i18n.t("donation.message.amount_range_error"))
    .typeError(i18n.t("donation.message.amount_range_error"))
    .min(minAmount, i18n.t("donation.message.amount_range_error"))
    .max(maxAmount, i18n.t("donation.message.amount_range_error")),
  bsbAccountNumber: yup
    .boolean()
    .required(i18n.t("donation.message.bsb_account_number_error"))
    .equals([true], i18n.t("donation.message.bsb_account_number_error")),
  cardNumber: yup
    .boolean()
    .required(i18n.t("donation.message.credit_card_error"))
    .equals([true], i18n.t("donation.message.credit_card_error")),
  cvv: yup.boolean().required(" ").equals([true], " "),
  // We use a custom regex here instead of yup's built-in email validation because
  // we want our validation to exactly match the one we use server-side.
  // If the client-side validation is looser than the server-side validation,
  // we'll see some email addresses being accepted as valid on the client,
  // payment taken, and then they'll be knocked back when we go to save the
  // donation on the server.
  email: yup
    .string()
    .required(i18n.t("donation.message.email_error"))
    .matches(emailRegex, i18n.t("donation.message.email_error")),
  firstName: yup
    .string()
    .required(i18n.t("donation.message.first_name_error"))
    .matches(/^[^@]+$/, i18n.t("donation.message.first_name_error")),
  iban: yup
    .boolean()
    .required(i18n.t("donation.message.iban_error"))
    .equals([true], i18n.t("donation.message.iban_error")),
  lastName: yup
    .string()
    .required(i18n.t("donation.message.last_name_error"))
    .matches(/^[^@]+$/, i18n.t("donation.message.last_name_error")),
  postcode: yup.string().when("giftAid", {
    is: true,
    then: (schema) => schema.required(i18n.t("donation.message.postcode_error")),
  }),
})

export { definitions }

export default class Validation {
  /**
   *
   * @param directDebitMethod one of "BECS" or "SEPA"
   * @param maxAmount the maximum amount for the region
   * @param minAmount the minimum amount for the region
   */
  constructor({ directDebitMethod = "BECS", maxAmount, minAmount }) {
    this.schemata = Validation.buildSchemata({
      directDebitMethod,
      maxAmount,
      minAmount,
    })
  }

  /**
   * Returns a yup schema to validate a set of fields appropriate for the given
   *   payment provider
   * @param paymentMethod one of "credit_card","paypal" and "wallet"
   * @return {*}
   */
  getSchema(paymentMethod) {
    return this.schemata[paymentMethod || "paypal"]
  }

  /**
   * Build the yup validation schemata
   * https://github.com/jquense/yup
   *
   * @param directDebitMethod one of "BECS" or "SEPA"
   * @param maxAmount the maximum amount for the region
   * @param minAmount the minimum amount for the region
   */
  static buildSchemata({ directDebitMethod, maxAmount, minAmount }) {
    const {
      accountName,
      address,
      amount,
      bsbAccountNumber,
      cardNumber,
      cvv,
      email,
      firstName,
      iban,
      lastName,
      postcode,
    } = definitions({
      maxAmount,
      minAmount,
    })

    let directDebit
    if (directDebitMethod === "SEPA") {
      directDebit = yup.object().shape({ iban, lastName })
    } else if (directDebitMethod === "Bacs") {
      directDebit = yup.object()
    } else {
      directDebit = yup.object().shape({ accountName, bsbAccountNumber })
    }
    const creditCard = yup.object().shape({ cardNumber, cvv })
    const identity = yup.object().shape({ address, email, firstName, postcode })
    const amountSchema = yup.object().shape({ amount })
    return {
      credit_card: amountSchema.concat(identity).concat(creditCard),
      direct_debit: amountSchema.concat(identity).concat(directDebit),
      paypal: amountSchema.concat(identity),
      wallet: amountSchema,
    }
  }

  /**
   * Validates the state of the donation form
   *
   * @param state the state of a `DonationFormManager`
   * @return {Promise<void>} a promise that resolves if validation is
   * successful, and is rejected with errors otherwise. Errors are formatted as:
   *   [{field: "message"}]
   */
  async validate(state) {
    try {
      await this.getSchema(state.paymentMethod).validate(state, {
        abortEarly: false,
      })
    } catch (err) {
      throw Validation.reformatErrors(err)
    }
  }

  static reformatErrors(err) {
    return Object.fromEntries(err.inner.map((ex) => [ex.path, ex.message]))
  }
}
