import React, { useCallback, useEffect, useState } from "react"
import classNames from "classnames"
import i18n from "lib/i18n"
import PropTypes from "prop-types"
import errorsPropType from "components/PropTypes/errorsPropType"
import DonationFrequency from "components/DonationFrequency"
import AmountButton from "components/AmountButton"
import CustomAmountButton from "components/CustomAmountButton"
import Analytics from "lib/Analytics"
import { chunk, flatten, isEqual } from "underscore"
import { Typography, withStyles } from "@theconversation/ui"

// if number of amounts is even, use 2, if odd, use 3
const MAX_AMOUNT_COLUMNS = [2, 3]
const GA_AMOUNT_LABEL = "Amount"
const GA_CUSTOM_AMOUNT_LABEL = "Custom Amount"

const styles = (theme) => ({
  amountDetails: {
    marginBottom: 24,
  },
  buttonContainer: {
    margin: "0 -5px",
  },
  label: {
    marginTop: "20px",
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: "Noto Sans",
    fontSize: "0.75rem",
    fontWeight: "bold",
    display: "block",
  },
  error: {
    color: theme.palette.error.border,
  },
})

const AmountDetails = ({
  active,
  amount,
  classes,
  currencySign,
  defaultAmounts,
  errors,
  frequency,
  initialFrequency,
  onChange,
  suggestedAmounts,
}) => {
  const [custom, setCustom] = useState(suggestedAmounts.indexOf(amount) === -1)

  // Be careful about changing this!
  //
  // This is here to deal with the case where the default amount for a frequency is not one of the
  // suggested values. When the user changes to that frequency, we need to recognise that and change
  // the amount to "custom".
  useEffect(() => {
    if (!custom && suggestedAmounts.indexOf(amount) === -1) {
      setCustom(true)
    }
  }, [custom, suggestedAmounts, amount])

  const setAmount = useCallback(
    (newAmount) => {
      Analytics.trackFormEvent("Donation Form", "Completed", GA_AMOUNT_LABEL, newAmount)
      setCustom(false)
      onChange({ amount: newAmount })
    },
    [onChange],
  )

  const setCustomAmount = useCallback(
    (newAmount) => {
      Analytics.trackFormEvent("Donation Form", "Completed", GA_CUSTOM_AMOUNT_LABEL, newAmount)
      setCustom(true)
      onChange({ amount: newAmount })
    },
    [onChange],
  )

  const setFrequency = useCallback(
    (newFrequency) => {
      const defaultAmount = defaultAmounts[newFrequency]
      setCustom(false)
      onChange({ amount: defaultAmount, frequency: newFrequency })
    },
    [onChange, defaultAmounts],
  )

  const amountSelected = useCallback(
    (currentAmount) => !custom && currentAmount === amount,
    [custom, amount],
  )

  const customAmount = useCallback(
    (currentAmount) => (custom ? currentAmount : ""), // eslint-disable-line no-extra-parens
    [custom],
  )

  const label = (currentAmount) => currencySign + currentAmount
  const error = () => {
    if (errors.amount) {
      Analytics.trackFormEvent("Donation Form", "Validation Error", GA_CUSTOM_AMOUNT_LABEL)
      return <span className={classes.error}>{errors.amount}</span>
    }
    return ""
  }

  const renderAmountButtons = () => {
    const numAmounts = suggestedAmounts.length
    const chunkedAmounts = chunk(suggestedAmounts, MAX_AMOUNT_COLUMNS[numAmounts % 2])

    return flatten(
      chunkedAmounts.map((amounts) =>
        amounts.map((buttonAmount) => (
          <AmountButton
            active={active}
            key={buttonAmount}
            label={label(buttonAmount)}
            secondaryLabel={
              amountSelected(buttonAmount) && frequency === "monthly"
                ? i18n.t("donation.button.per_month")
                : null
            }
            onClick={() => setAmount(buttonAmount)}
            selected={amountSelected(buttonAmount)}
            width={`${100 / amounts.length}%`}
          />
        )),
      ),
    )
  }

  return (
    <div className={classes.amountDetails}>
      <Typography variant="h6">{i18n.t("donation.heading.amount_and_frequency")}</Typography>
      <input id="donation_amount" type="hidden" value={amount} name="donation[amount]" />
      <input id="donation_frequency" type="hidden" value={frequency} name="donation[frequency]" />

      <DonationFrequency
        active={active}
        initial={initialFrequency}
        onChange={setFrequency}
        selected={frequency}
      />
      <label
        htmlFor="customAmount"
        className={classNames({ [classes.label]: true, [classes.error]: !!errors.amount })}
      >
        {frequency === "monthly"
          ? i18n.t("donation.label.amount_per_month")
          : i18n.t("donation.label.amount")}
      </label>

      <div className={classes.buttonContainer}>
        {renderAmountButtons()}
        <CustomAmountButton
          active={active}
          amount={customAmount(amount)}
          error={!!errors.amount}
          label={currencySign}
          secondaryLabel={frequency === "monthly" ? i18n.t("donation.button.per_month") : null}
          onClick={setCustomAmount}
          onInput={setCustomAmount}
          selected={custom}
          width="100%"
        />
      </div>
      {error()}
    </div>
  )
}

AmountDetails.defaultProps = {
  active: true,
  amount: "100",
  classes: {
    amountDetails: "",
    label: "",
  },
  currencySign: "$",
  defaultAmounts: {
    once: "100",
    monthly: "20",
  },
  errors: {},
  frequency: "once",
  initialFrequency: "once",
  onChange: () => {},
  suggestedAmounts: ["10", "30", "100"],
}

AmountDetails.propTypes = {
  active: PropTypes.bool,
  amount: PropTypes.string,
  classes: PropTypes.shape({
    amountDetails: PropTypes.string,
    buttonContainer: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
  }),
  currencySign: PropTypes.string,
  defaultAmounts: PropTypes.shape({ once: PropTypes.string, monthly: PropTypes.string }),
  errors: errorsPropType,
  frequency: PropTypes.oneOf(["unfilled", "once", "monthly"]),
  initialFrequency: PropTypes.oneOf(["once", "monthly"]),
  onChange: PropTypes.func,
  suggestedAmounts: PropTypes.arrayOf(PropTypes.string),
}

export default withStyles(styles)(
  React.memo(AmountDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps)),
)
