import i18n from "lib/i18n"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import AmountButton from "components/AmountButton"
import { TextField, withStyles } from "@theconversation/ui"

const styles = {
  container: {
    marginTop: 3,
    boxShadow: "none",
    border: "1px solid #ccc",
    borderRadius: 3,
  },
  field: {
    maxWidth: 236,
    marginRight: "0.5em",
  },
}

const CustomAmountButton = ({
  active,
  amount,
  classes,
  error,
  label,
  secondaryLabel,
  onClick,
  onInput,
  selected,
  width,
}) => {
  const [loaded, setLoaded] = useState(false)
  const amountInput = useRef(null)

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
    }
  }, [loaded])

  const handleClick = () => {
    amountInput.current.focus()
    onClick(amountInput.current.value)
  }

  const handleChange = (event) => {
    onInput(event.target.value)
  }

  return (
    <AmountButton
      active={active}
      className={classes.container}
      label={label}
      secondaryLabel={secondaryLabel}
      margin="0"
      onClick={handleClick}
      selected={selected}
      width={width}
    >
      <TextField
        autoComplete={false}
        className={classes.field}
        disabled={!active}
        error={error}
        id="customAmount"
        inputRef={amountInput}
        onChange={handleChange}
        pattern="[0-9]*"
        placeholder={i18n.t("donation.placeholder.custom_amount")}
        type="number"
        value={amount}
      />
    </AmountButton>
  )
}

CustomAmountButton.defaultProps = {
  active: true,
  amount: "100",
  classes: {
    container: "",
    field: "",
  },
  error: false,
  label: "",
  secondaryLabel: "",
  onClick: () => {},
  onInput: () => {},
  selected: false,
  width: "40%",
}

CustomAmountButton.propTypes = {
  active: PropTypes.bool,
  amount: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string,
    field: PropTypes.string,
  }),
  error: PropTypes.bool,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onClick: PropTypes.func,
  onInput: PropTypes.func,
  selected: PropTypes.bool,
  width: PropTypes.string,
}

export default withStyles(styles)(CustomAmountButton)
