/* eslint-disable react/no-danger */

import i18n from "lib/i18n"
import PropTypes from "prop-types"
import React from "react"

import styles from "./Disclosure.styles.css"

export default function Disclosure({ enabled }) {
  if (!enabled) {
    return <React.Fragment />
  }

  const disclosureHtml = { __html: i18n.t("recaptcha.disclosure_html") }

  return <div className={styles.disclosure} dangerouslySetInnerHTML={disclosureHtml} />
}

Disclosure.propTypes = {
  enabled: PropTypes.bool.isRequired,
}
