import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@theconversation/ui"

const styles = (theme) => ({ error: { color: theme.palette.error.border } })

const FormError = ({ classes, error }) => {
  if (error) {
    return (
      <ul className={classes.error}>
        <li key={error}>{error}</li>
      </ul>
    )
  }
  return ""
}

FormError.defaultProps = {
  classes: {
    error: "",
  },
  error: "",
}

FormError.propTypes = {
  classes: PropTypes.shape({
    error: PropTypes.string,
  }),
  error: PropTypes.string,
}

export default withStyles(styles)(FormError)
