import ErrorTrack from "lib/ErrorTrack"

// Given a document.location, allows extraction of
// query string values for presets for particular
// donation campaigns or promos.
export default class Presets {
  constructor(location) {
    // URL API is not available in IE, so if it's
    // undefined, we just quack like URLSearchParams
    // and return null for every get, which is what
    // get returns if the given key has no value.
    if (typeof URL === "undefined" || location === undefined) {
      this.searchParams = { get: () => null }
    } else {
      try {
        const url = new URL(location)
        this.searchParams = url.searchParams
      } catch (e) {
        ErrorTrack.notify(e)
        this.searchParams = { get: () => null }
      }
    }
  }

  frequency() {
    return this.searchParams.get("frequency")
  }

  amount() {
    const amount = parseInt(this.searchParams.get("amount"))

    if (isNaN(amount) || amount <= 0) {
      return null
    }

    return amount.toString()
  }

  paymentMethod() {
    return this.searchParams.get("payment_method")
  }
}
