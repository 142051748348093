import React from "react"
import PropTypes from "prop-types"
import Analytics from "lib/Analytics"
import i18n from "lib/i18n"
import { ToggleButtonGroup, ToggleButton, withStyles } from "@theconversation/ui"

const styles = {
  toggleContainer: {
    marginTop: 3,
    boxShadow: "none",
    width: "100%",
  },
  label: {
    marginTop: "20px",
    color: "rgba(0, 0, 0, 0.54)",
    fontFamily: "Noto Sans",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textTransform: "none",
    display: "block",
  },
}

const StyledToggleButton = withStyles({
  root: {
    border: "1px solid hsl(240deg 5% 85%)",
    borderRadius: "2px",
    fontSize: 16,
    fontWeight: 500,
    height: 36,
    width: "50%",
    color: "hsl(0deg 0% 13%)",
    fontFamily: "Montserrat",
  },
  selected: {
    background: "hsl(196deg 80% 40%) !important",
    borderColor: "hsl(196deg 80% 40%) !important",
    color: "hsl(0deg 0% 100%) !important",
    fontWeight: 600,
  },
})(ToggleButton)

const DonationFrequency = ({ active, classes, initial, onChange, selected }) => {
  const once = (e) => {
    e.preventDefault()
    Analytics.trackFormEvent("Donation Form", "Completed", "Frequency", "once")
    onChange("once")
  }

  const monthly = (e) => {
    e.preventDefault()
    Analytics.trackFormEvent("Donation Form", "Completed", "Frequency", "monthly")
    onChange("monthly")
  }

  const isSelected = (frequency) => selected === frequency

  // eslint-disable-next-line react/prop-types
  const OnceButton = ({ className }) => (
    <StyledToggleButton
      className={className}
      disabled={!active}
      onClick={once}
      selected={isSelected("once")}
      value="once"
    >
      {i18n.t("donation.frequency.once")}
    </StyledToggleButton>
  )

  // eslint-disable-next-line react/prop-types
  const MonthlyButton = ({ className }) => (
    <StyledToggleButton
      className={className}
      disabled={!active}
      onClick={monthly}
      selected={isSelected("monthly")}
      value="monthly"
    >
      {i18n.t("donation.frequency.monthly")}
    </StyledToggleButton>
  )

  const orderedFrequencyButtons = (initialFrequency) => {
    switch (initialFrequency) {
      case "monthly":
        return [<MonthlyButton key="monthly" />, <OnceButton key="once" />]
      default:
        return [<OnceButton key="once" />, <MonthlyButton key="monthly" />]
    }
  }

  return (
    <div>
      <label htmlFor="frequency" className={classes.label}>
        {i18n.t("donation.label.frequency")}
      </label>
      <ToggleButtonGroup
        id="frequency"
        value={selected}
        className={classes.toggleContainer}
        exclusive
      >
        {orderedFrequencyButtons(initial)}
      </ToggleButtonGroup>
    </div>
  )
}

DonationFrequency.defaultProps = {
  active: true,
  classes: {
    toggleContainer: "",
    label: "",
  },
  initial: "once",
  onChange: () => {},
  selected: "once",
}

DonationFrequency.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.shape({
    toggleContainer: PropTypes.string,
    label: PropTypes.string,
  }),
  initial: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.string,
}

export default withStyles(styles)(DonationFrequency)
