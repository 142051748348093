/* eslint class-methods-use-this: ["error", { "exceptMethods": ["onRegionSelect"] }] */
/* eslint-disable
  react/no-danger
*/
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import i18n from "lib/i18n"
import { Dropdown, withStyles } from "@theconversation/ui"
import watchWindowWidth from "lib/watchWindowWidth"

import { ConfigProps } from "components/PropTypes/ConfigProps"
import HeroImage from "./HeroImage"
import styles from "./styles.scss"

const HeroComponent = ({ config, regions, transactionId }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => watchWindowWidth(setWindowWidth), [])

  const onRegionSelect = (event) => {
    window.location = `/${event.target.value}`
  }

  const deviceWidthStyles = () => {
    if (windowWidth > 600) {
      return styles.desktop
    }

    return styles.mobile
  }

  let containerCopy
  if (transactionId) {
    containerCopy = (
      <div className={classNames(styles.container, styles.withTransactionId)}>
        <h6 className={styles.transactionId}>
          {i18n.t("hero_component.label.transaction_number")} {transactionId}
        </h6>
        <h1
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: i18n.t("hero_component.label.thank_you_for_donating"),
          }}
        />
      </div>
    )
  } else {
    const regionOptions = regions.map((region) => (
      <option key={region.code} value={region.code}>
        {region.name}
      </option>
    ))

    containerCopy = (
      <div className={styles.container}>
        <h1 className={styles.title}>{i18n.t("hero_component.label.title")}</h1>
        <h2 className={styles.donatingTo}>{i18n.t("hero_component.label.you_are_donating_to")}</h2>

        <Dropdown
          native
          value={config.region_code}
          onChange={onRegionSelect}
          className={styles.regionSelector}
          name="regionSelector"
        >
          {regionOptions}
        </Dropdown>
      </div>
    )
  }

  return (
    <div className={deviceWidthStyles()}>
      <HeroImage />
      {containerCopy}
    </div>
  )
}

HeroComponent.defaultProps = {
  config: {},
  regions: [],
  transactionId: null,
}

HeroComponent.propTypes = {
  config: ConfigProps,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  transactionId: PropTypes.number,
}

export default withStyles(styles)(HeroComponent)
