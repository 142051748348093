import PropTypes from "prop-types"

const directDebitMethodPropType = PropTypes.oneOf(["none", "Bacs", "BECS", "SEPA"])

const ConfigProps = PropTypes.shape({
  amount_min: PropTypes.number,
  amount_max: PropTypes.number,
  default_amounts_in_dollars: PropTypes.shape({
    once: PropTypes.string,
    monthly: PropTypes.string,
  }),
  default_frequency: PropTypes.string,
  direct_debit_method: directDebitMethodPropType,
  frequency_allowed_values: PropTypes.array,
  gift_aid_on_front_page: PropTypes.bool,
  locale: PropTypes.string,
  Paypal_allowed: PropTypes.bool,
  Paypal_env: PropTypes.string,
  recaptcha_enabled: PropTypes.bool.isRequired,
  recaptcha_siteKey: PropTypes.string,
  region_code: PropTypes.string,
  region_currency_code: PropTypes.string,
  region_currency_sign: PropTypes.string,
  region_home_country_code: PropTypes.string,
  Stripe_PublishableKey: PropTypes.string,
  show_tax_id: PropTypes.bool,
  suggested_amounts_in_dollars: PropTypes.shape({
    once: PropTypes.arrayOf(PropTypes.string),
    monthly: PropTypes.arrayOf(PropTypes.string),
  }),
  user_features: PropTypes.array,
})

const ConfigDefaultProps = {
  amount_min: 5,
  amount_max: 5000,
  default_amounts_in_dollars: {
    once: "100",
    monthly: "20",
  },
  default_frequency: "once",
  direct_debit_method: "none",
  frequency_allowed_values: ["once", "monthly"],
  gift_aid_on_front_page: false,
  locale: "en-AU",
  Paypal_allowed: true,
  Paypal_env: "sandbox",
  recaptcha_enabled: false,
  recaptcha_siteKey: "super-site-key",
  region_currency_code: "jpy",
  region_currency_sign: "¥",
  region_home_country_code: "DZ",
  show_tax_id: false,
  Stripe_PublishableKey: "stripe_publishable_key",
  suggested_amounts_in_dollars: {
    once: ["30", "100"],
    monthly: ["10", "25"],
  },
  user_features: [],
}

export { ConfigProps, ConfigDefaultProps }
