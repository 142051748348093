import DirectDebitProcessor from "lib/DirectDebitProcessor"

export default class BacsDirectDebitProcessor extends DirectDebitProcessor {
  /**
   * Executes a Bacs payment, submitting the donation details to our back end,
   * which will return a checkout URL for us to navigate to
   * @override
   */
  async executePayment() {
    const { donationDetails } = this
    const result = await this.submitForm({ donationDetails })
    return result.stripe_checkout_url
  }
}
