import { createMuiTheme } from "@material-ui/core/styles"

// Custom donations theme, so we can migrate to Material UI 4 (`@theconversation/ui@2.19.0)`
// maintaining visual parity from the previous version
const donationsTheme = createMuiTheme({
  palette: {
    // MUI theming, providing colors directly:
    // https://material-ui.com/customization/palette/#providing-the-colors-directly
    primary: {
      main: "hsl(196deg 80% 40%)",
      contrastText: "hsl(0deg 0% 100%)",
    },
    error: {
      main: "hsl(4deg 100% 30%)",
      border: "hsl(4deg 100% 30%)",
    },
  },
  overrides: {
    MuiSelect: {
      root: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem",
      },
    },
    MuiTooltip: {
      arrow: {
        display: "none",
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    fontFamily: "Noto sans",
    h5: {
      fontSize: "1.5rem !important",
    },
    h6: {
      fontSize: "1.25rem !important",
      letterSpacing: "normal !important",
      lineHeight: "32px !important",
    },
    body2: {
      fontSize: "0.875rem",
    },
    subtitle1: {
      fontSize: "0.875rem !important",
      lineHeight: "21px !important",
      paddingBottom: 10,
    },
    subtitle2: {
      fontSize: "0.75rem",
    },
    button: {
      textTransform: "none",
    },
  },
})

export default donationsTheme
