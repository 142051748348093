import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Analytics from "lib/Analytics"
import { FormControlLabel, Radio, Typography, withStyles } from "@theconversation/ui"
import FormFieldset from "components/FormFieldset"
import i18n from "lib/i18n"
import paymentMethodPropType from "components/PropTypes/paymentMethodPropType"

const styles = {
  methodFieldset: {
    marginBottom: -10,
  },
  paymentMethod: {
    overflow: "visible",
    margin: "0 auto 20px",
  },
}

const PaymentMethodChooser = ({
  paymentMethod,
  active,
  classes,
  creditCardAvailable,
  directDebitAvailable,
  payPalAvailable,
  onChange,
}) => {
  const handleChange = useCallback(
    (e) => {
      const paymentMethod = e.target.value
      Analytics.trackFormEvent("Donation Form", "Completed", "Payment Method", paymentMethod)
      onChange({ paymentMethod })
    },
    [onChange],
  )

  const numProviders = [creditCardAvailable, directDebitAvailable, payPalAvailable].filter(
    (p) => p,
  ).length

  if (numProviders < 2) {
    return null
  }

  const creditCard = creditCardAvailable ? (
    <fieldset className={classes.methodFieldset}>
      <FormControlLabel
        control={
          <Radio
            aria-label={i18n.t("donation.label.credit_card")}
            checked={paymentMethod === "credit_card"}
            color="primary"
            disabled={!active}
            name="payment_method"
            onChange={handleChange}
            value="credit_card"
          />
        }
        label={i18n.t("donation.label.credit_card")}
        labelPlacement="end"
        value="credit_card"
      />
    </fieldset>
  ) : null

  const directDebit = directDebitAvailable ? (
    <fieldset className={classes.methodFieldset}>
      <FormControlLabel
        control={
          <Radio
            aria-label={i18n.t("donation.label.direct_debit")}
            checked={paymentMethod === "direct_debit"}
            color="primary"
            disabled={!active}
            name="payment_method"
            onChange={handleChange}
            value="direct_debit"
          />
        }
        label={i18n.t("donation.label.direct_debit")}
        labelPlacement="end"
        value="direct_debit"
      />
    </fieldset>
  ) : null

  const paypal = payPalAvailable ? (
    <fieldset>
      <FormControlLabel
        control={
          <Radio
            aria-label="Paypal"
            checked={paymentMethod === "paypal"}
            color="primary"
            disabled={!active}
            name="payment_method"
            onChange={handleChange}
            value="paypal"
          />
        }
        label="PayPal"
        labelPlacement="end"
        value="paypal"
      />
    </fieldset>
  ) : null

  return (
    <div className={classes.paymentMethod} id="payment_method">
      <FormFieldset>
        <Typography variant="h6">{i18n.t("donation.heading.payment_method")}</Typography>
      </FormFieldset>

      {creditCard}
      {directDebit}
      {paypal}
    </div>
  )
}

export default React.memo(withStyles(styles)(PaymentMethodChooser))

PaymentMethodChooser.defaultProps = {
  active: true,
  onChange: () => {},
  creditCardAvailable: false,
  directDebitAvailable: false,
  payPalAvailable: false,
  paymentMethod: "credit_card",
}

PaymentMethodChooser.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.shape({
    methodFieldset: PropTypes.string,
    paymentMethod: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  creditCardAvailable: PropTypes.bool,
  directDebitAvailable: PropTypes.bool,
  payPalAvailable: PropTypes.bool,
  paymentMethod: paymentMethodPropType,
}
