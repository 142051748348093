/**
 * Co-ordinates PayPal payment processes with the donation form, supplying
 *   callbacks for the PayPal button which are triggered during the process
 */
import PayPalOnceOffFlow from "lib/PayPalOnceOffFlow"
import PayPalRecurringFlow from "lib/PayPalRecurringFlow"

export default class PayPalManager {
  /**
   * Creates a `PayPalManager`
   * @param allowed true if PayPal is configured for the current region
   * @param donationForm a React ref to the donation form
   * @param env the PayPal environment; either "production" or "sandbox"
   * @param tcDonateGateway an instance of `TcDonateGateway`, used to call
   *   the TC-Donate backend
   */
  constructor({ allowed, donationForm, env, tcDonateGateway }) {
    this.allowed = allowed
    this.completed = false
    this.donationForm = donationForm
    this.env = env
    this.tcDonateGateway = tcDonateGateway
    this.payPalOnceOffFlow = new PayPalOnceOffFlow({ payPalManager: this })
    this.payPalRecurringFlow = new PayPalRecurringFlow({
      payPalManager: this,
      tcDonateGateway,
    })
    this.onCancel = this.onCancel.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  /**
   * Exposes the amount field from this.donationDetails
   * @return {*}
   */
  get amount() {
    return this.donationDetails.amount
  }

  /**
   * A set of callbacks to add to the PayPalButton, which change according to
   * which payment flow we're using
   * @return {{createOrder: {(): (Promise<void>|undefined|Promise<*>)}, onApprove: {(*): void}}}
   */
  get callbacks() {
    return this.paymentFlow.callbacks
  }

  /**
   * Internal interface to expose the current `donationDetails` on the `donationForm`
   * @return {{lastName: *, amount: *, title: *, frequency: *, name: *,
   *   recaptchaToken: *, paypal: boolean, email: *}}
   */
  get donationDetails() {
    return this.donationForm.current.donationDetails
  }

  /**
   * Internal interface to expose `donationForm`'s `fail()` method, so we can
   *   call it if the payment process fails
   * @param error
   */
  fail(error) {
    this.donationForm.current.fail(error)
  }

  /**
   * Exposes the frequency field from this.donationDetails
   * @return {*}
   */
  get frequency() {
    return this.donationDetails.frequency
  }

  /**
   * Exposes donation form's `onCancel` callback, so this can hook it up to
   *   the PayPal button
   */
  onCancel() {
    this.donationForm.current.onCancel()
  }

  /**
   * Exposes donation form's `onPaypalClick` callback, so this can hook it up to
   *   the PayPal button
   */
  onClick() {
    this.donationForm.current.onPaypalClick()
  }

  /**
   * Internal interface exposing the current payment flow
   * @return {PayPalOnceOffFlow|PayPalRecurringFlow}
   */
  get paymentFlow() {
    if (this.frequency === "once") {
      return this.payPalOnceOffFlow
    }
    return this.payPalRecurringFlow
  }

  /**
   * Posts details of a completed donation to the TC-Donate backend
   * @param paymentData an object with details of an approved order (e.g.
   * `{ paypalOrderId: "123ABC" }`) or subscription returned from PayPal
   * @return {Promise<void>}
   */
  async postDonation(paymentData) {
    const donation = {
      paymentTokens: { paymentData },
      ...this.donationDetails,
    }
    try {
      const result = await this.tcDonateGateway.call("donations.json", "POST", { donation })
      this.successUrl = result.success_url
    } catch (result) {
      this.fail(result)
      throw result
    }
  }

  /**
   * Updates details of a donation to the TC-Donate backend
   * @param payPalId the ID of the PayPal order/subscription to update
   * @param paymentData an object with details of an approved order (e.g.
   * `{ paypalOrderId: "123ABC" }`) or subscription returned from PayPal
   * @return {Promise<void>}
   */
  async putDonation(payPalId, paymentData) {
    const subscription = {
      paymentData,
      ...this.donationDetails,
    }
    try {
      await this.tcDonateGateway.call(
        `subscriptions/${payPalId}.json`,
        "PUT",
        { subscription },
        "/api/v1",
      )
      // Stop interacting with the PayPal button once the transaction is
      // completed, so PayPal doesn't raise exceptions
      this.completed = true
      this.succeed()
    } catch (result) {
      this.fail(result)
    }
  }

  /**
   * Exposes `donationForm`'s `regionCode` attribute
   * @return {*}
   */
  get regionCode() {
    return this.donationForm.current.regionCode
  }

  /**
   * Expose `donationForm`'s `loading()` method, so we can call it when a paypal
   *   payment is in progress.
   */
  loading() {
    this.donationForm.current.loading()
  }

  /**
   * Expose `donationForm`'s `cancelloading()` method, so we can call it when a paypal
   *   payment is cancelled before completion.
   */
  cancelLoading() {
    this.donationForm.current.cancelLoading()
  }

  /**
   * Internal interface to expose `donationForm`'s `succeed()` method, so we can
   *   call it if the payment process succeeds
   */
  succeed() {
    this.donationForm.current.succeed(this.successUrl)
  }
}
