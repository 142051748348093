import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { isEqual } from "underscore"
import BecsDirectDebitDetails from "components/BecsDirectDebitDetails"
import CreditCardDetails from "components/CreditCardDetails"
import CreditCardProcessor from "lib/CreditCardProcessor"
import DirectDebitProcessor from "lib/DirectDebitProcessor"
import BecsDirectDebitProcessor from "lib/BecsDirectDebitProcessor"
import SepaDirectDebitProcessor from "lib/SepaDirectDebitProcessor"
import i18n from "lib/i18n"
import errorsPropType from "components/PropTypes/errorsPropType"
import NullDirectDebitProcessor from "lib/NullDirectDebitProcessor"
import paymentMethodPropType from "components/PropTypes/paymentMethodPropType"
import PaymentMethodChooser from "components/PaymentMethodChooser"
import PayPalButton from "components/PayPalButton"
import PayPalManager from "lib/PayPalManager"
import PrivacyNotice from "components/PrivacyNotice"
import SepaDirectDebitDetails from "components/SepaDirectDebitDetails"
import SubmitButton from "components/SubmitButton"

const PaymentDetails = ({
  accountName,
  amount,
  bsbAccountNumber,
  cardNumber,
  cvv,
  creditCardProcessor,
  directDebitProcessor,
  errors,
  expiry,
  fieldsActive,
  frequency,
  iban,
  locale,
  onChange,
  paymentMethod,
  payPalManager,
  submitActive,
  valid,
}) => {
  const showCreditCard = creditCardProcessor.available && paymentMethod === "credit_card"
  const showDirectDebit = directDebitProcessor.available && paymentMethod === "direct_debit"
  const showBecsDetails =
    showDirectDebit && directDebitProcessor instanceof BecsDirectDebitProcessor
  const showSepaDetails =
    showDirectDebit && directDebitProcessor instanceof SepaDirectDebitProcessor
  const showButton = showCreditCard || showDirectDebit

  const buttonText = useMemo(() => {
    if (frequency === "monthly") {
      return i18n.t("donation.button.donate_monthly", { amount })
    }
    return i18n.t("donation.button.donate_once", { amount })
  }, [frequency, amount])

  return (
    <React.Fragment>
      <PaymentMethodChooser
        active={fieldsActive}
        onChange={onChange}
        creditCardAvailable={creditCardProcessor.available}
        directDebitAvailable={directDebitProcessor.available}
        payPalAvailable={payPalManager.allowed}
        paymentMethod={paymentMethod}
      />

      {showCreditCard && (
        <CreditCardDetails
          cardNumber={cardNumber}
          cvv={cvv}
          errors={errors}
          expiry={expiry}
          fieldsActive={fieldsActive}
          onChange={onChange}
        />
      )}
      {showBecsDetails && (
        <BecsDirectDebitDetails
          accountName={accountName}
          active={fieldsActive}
          bsbAccountNumber={bsbAccountNumber}
          errors={errors}
          onChange={onChange}
        />
      )}
      {showSepaDetails && (
        <SepaDirectDebitDetails
          active={fieldsActive}
          errors={errors}
          iban={iban}
          onChange={onChange}
        />
      )}
      <PrivacyNotice />
      {payPalManager.allowed && (
        <PayPalButton
          disabled={!submitActive}
          error={errors.paypal}
          locale={locale}
          paymentMethod={paymentMethod}
          payPalManager={payPalManager}
          preventDefault={!valid}
        />
      )}
      {showButton && <SubmitButton active={submitActive} text={buttonText} />}
    </React.Fragment>
  )
}

PaymentDetails.defaultProps = {
  accountName: "",
  amount: undefined,
  bsbAccountNumber: false,
  cardNumber: false,
  cvv: false,
  creditCardProcessor: new CreditCardProcessor({}),
  directDebitProcessor: new NullDirectDebitProcessor({}),
  errors: {},
  expiry: false,
  fieldsActive: false,
  frequency: "once",
  iban: false,
  locale: "en",
  onChange: () => {},
  paymentMethod: "credit_card",
  payPalManager: new PayPalManager({}),
  submitActive: false,
  valid: true,
}

PaymentDetails.propTypes = {
  accountName: PropTypes.string,
  amount: PropTypes.string,
  bsbAccountNumber: PropTypes.bool,
  cardNumber: PropTypes.bool,
  cvv: PropTypes.bool,
  creditCardProcessor: PropTypes.instanceOf(CreditCardProcessor),
  directDebitProcessor: PropTypes.instanceOf(DirectDebitProcessor),
  errors: errorsPropType,
  expiry: PropTypes.bool,
  fieldsActive: PropTypes.bool,
  frequency: PropTypes.oneOf(["once", "monthly"]),
  iban: PropTypes.bool,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  paymentMethod: paymentMethodPropType,
  payPalManager: PropTypes.instanceOf(PayPalManager),
  submitActive: PropTypes.bool,
  valid: PropTypes.bool,
}

export default React.memo(PaymentDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps))
