import React, { useRef, useImperativeHandle } from "react"
import PropTypes from "prop-types"

// Wrapper for Stripe inputs within material-ui
// https://medium.com/javascript-in-plain-english/stripe-payment-form-with-reactjs-and-material-ui-part-4-118e60fca962
// https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef()
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }))
  return (
    <Component
      onReady={(element) => {
        elementRef.current = element
      }}
      {...props}
    />
  )
}

StripeInput.defaultProps = {
  component: () => {},
  inputRef: () => {},
}

StripeInput.propTypes = {
  component: PropTypes.func,
  inputRef: PropTypes.func,
}

export default StripeInput
