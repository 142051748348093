import React from "react"
import PropTypes from "prop-types"
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"
import paymentMethodPropType from "components/PropTypes/paymentMethodPropType"
import PayPalManager from "lib/PayPalManager"
import { withStyles } from "@theconversation/ui"

const HIDDEN_STYLES = {
  display: "none",
}

const DISABLED_STYLES = {
  opacity: 0.2,
}

const styles = (theme) => ({ error: { color: theme.palette.error.border } })

const PayPalButton = ({
  classes,
  disabled,
  error,
  locale,
  paymentMethod,
  payPalManager,
  preventDefault,
}) => {
  const [{ isResolved }] = usePayPalScriptReducer()

  const donationFormLoaded = () => {
    const donationFormRef = payPalManager.donationForm
    return !!(donationFormRef && donationFormRef.current)
  }

  if (!donationFormLoaded()) {
    return null
  }

  const errorSpan = error ? <span className={classes.error}>{error}</span> : ""
  const visibleStyle = paymentMethod === "paypal" ? {} : HIDDEN_STYLES
  const disabledStyle = disabled ? DISABLED_STYLES : {}
  const activeClass = disabled ? "disabled" : "enabled"

  const { callbacks, onCancel, onClick } = payPalManager
  const style = { layout: "horizontal", size: "responsive", locale }

  // We pass className="!opacity-100" below to override PayPalButtons' default disabled styling
  // We want to have our button appear to be enabled when the form is invalid, but in that state
  // we don't want a button click to trigger PayPal's payment process

  const buttonsEl = isResolved ? (
    <PayPalButtons
      {...callbacks}
      className="!opacity-100"
      disabled={disabled || preventDefault}
      commit={true}
      env={payPalManager.env}
      onCancel={onCancel}
      onClick={onClick}
      style={style}
      forceReRender={[payPalManager.frequency]}
    />
  ) : null

  return (
    <div
      id="paypal_container"
      data-testid="paypal_container"
      className={activeClass}
      style={{ ...visibleStyle, ...disabledStyle }}
    >
      <div id="paypal_button">{buttonsEl}</div>
      {errorSpan}
    </div>
  )
}

PayPalButton.defaultProps = {
  classes: { error: "" },
  disabled: false,
  error: "",
  locale: "",
  preventDefault: false,
  paymentMethod: "",
  payPalManager: new PayPalManager({}),
}

PayPalButton.propTypes = {
  classes: PropTypes.shape({ error: PropTypes.string }),
  disabled: PropTypes.bool,
  error: PropTypes.node,
  locale: PropTypes.string,
  preventDefault: PropTypes.bool,
  paymentMethod: paymentMethodPropType,
  payPalManager: PropTypes.instanceOf(PayPalManager),
}

export default withStyles(styles)(PayPalButton)
