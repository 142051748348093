import React, { useCallback } from "react"
import i18n from "lib/i18n"
import PropTypes from "prop-types"
import errorsPropType from "components/PropTypes/errorsPropType"
import { isEqual } from "underscore"
import { Typography, withStyles } from "@theconversation/ui"
import Dropdown from "components/MemoisedDropdown"
import TextField from "components/MemoisedTextField"
import FormFieldset from "components/FormFieldset"

import Analytics from "lib/Analytics"

const track = (field) => (event) => {
  if (event.target.value.length) {
    Analytics.trackFormEvent("Donation Form", "Completed", field)
  } else {
    Analytics.trackFormEvent("Donation Form", "Skipped", field)
  }
}

const styles = {
  contactDetails: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
}

const TITLES = {
  en: ["Mr", "Mrs", "Ms", "Mx", "Dr", "Prof"],
  fr: ["M", "Mme", "Mlle", "Dr", "Pr"],
}
const LANGUAGES = Object.keys(TITLES)

const ContactDetails = ({
  active,
  classes,
  email,
  errors,
  firstName,
  lastName,
  objectName,
  onChange,
  title,
}) => {
  const trackFirstName = useCallback((event) => track("FirstName")(event), [])
  const trackLastName = useCallback((event) => track("LastName")(event), [])
  const trackEmail = useCallback((event) => track("Email")(event), [])

  const setEmail = useCallback((event) => onChange({ email: event.target.value }), [onChange])

  const setTitle = useCallback(
    (event) => {
      track("Title")(event)
      onChange({ title: event.target.value })
    },
    [onChange],
  )

  const setFirstName = useCallback(
    (event) => onChange({ firstName: event.target.value }),
    [onChange],
  )

  const setLastName = useCallback((event) => onChange({ lastName: event.target.value }), [onChange])

  // Work through the fallback list of languages till we find one which we have
  // a title list for.
  const language = i18n.firstAvailableLanguage(LANGUAGES)
  const titleOptions = TITLES[language].map((titleStr) => (
    <option key={titleStr} value={titleStr}>
      {titleStr}
    </option>
  ))

  return (
    <div id="contact-details" className={classes.contactDetails}>
      <FormFieldset>
        <Typography variant="h6">{i18n.t("donation.heading.contact_details")}</Typography>
      </FormFieldset>

      <FormFieldset>
        <Dropdown
          className={classes.menu}
          disabled={!active}
          id={`${objectName}_title`}
          label={i18n.t("donation.label.title")}
          name={`${objectName}[title]`}
          native
          onChange={setTitle}
          value={title}
        >
          <option value="" />
          {titleOptions}
        </Dropdown>
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete="given-name"
          disabled={!active}
          error={!!errors.firstName}
          fullWidth
          helperText={errors.firstName}
          id={`${objectName}_first_name`}
          label={i18n.t("donation.label.first_name")}
          name={`${objectName}[first_name]`}
          onBlur={trackFirstName}
          onChange={setFirstName}
          type="text"
          value={firstName}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.lastName}
          fullWidth
          helperText={errors.lastName}
          id={`${objectName}_last_name`}
          label={i18n.t("donation.label.last_name")}
          name={`${objectName}[last_name]`}
          onBlur={trackLastName}
          onChange={setLastName}
          type="text"
          value={lastName}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.email}
          fullWidth
          helperText={errors.email}
          id={`${objectName}_email`}
          label={i18n.t("donation.label.email_address")}
          name={`${objectName}[email]`}
          onBlur={trackEmail}
          onChange={setEmail}
          type="email"
          value={email}
        />
      </FormFieldset>
    </div>
  )
}

ContactDetails.defaultProps = {
  active: false,
  classes: {
    menu: "",
  },
  email: "",
  errors: {},
  firstName: "",
  lastName: "",
  objectName: "donation",
  onChange: () => {},
  title: "",
}

ContactDetails.propTypes = {
  active: PropTypes.bool,
  classes: PropTypes.shape({
    contactDetails: PropTypes.string,
    menu: PropTypes.string,
  }),
  email: PropTypes.string,
  errors: errorsPropType,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  objectName: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
}

export default withStyles(styles)(
  React.memo(ContactDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps)),
)
