import React from "react"
import PropTypes from "prop-types"
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js"

// When acceptance testing wallet payments, we need to provide a mock
// payment request button. This component will provide one when the
// payment request is marked as a mock.
const PaymentRequestButton = ({ options, onClick }) => {
  if (options.paymentRequest.isMock) {
    return <button onClick={onClick}>Mock Wallet Payment</button>
  }

  return <PaymentRequestButtonElement options={options} onClick={onClick} />
}

export default PaymentRequestButton

PaymentRequestButton.defaultProps = {
  options: {
    paymentRequest: {
      isMock: false,
    },
  },
}

PaymentRequestButton.propTypes = {
  options: PropTypes.shape({
    paymentRequest: PropTypes.shape({
      isMock: PropTypes.bool,
    }),
  }),
  onClick: PropTypes.func.isRequired,
}
