import PropTypes from "prop-types"

const errorsPropType = PropTypes.shape({
  accountNumber: PropTypes.string,
  amount: PropTypes.string,
  bsbAccountNumber: PropTypes.string,
  cardNumber: PropTypes.string,
  cvv: PropTypes.string,
  email: PropTypes.string,
  expiry: PropTypes.string,
  frequency: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  payment_error: PropTypes.string,
  paypal: PropTypes.string,
  taxId: PropTypes.string,
  title: PropTypes.string,
})

export default errorsPropType
