/* eslint-disable no-undef */
/* eslint "no-param-reassign": 0 */

// Adds JS standard library features not present in all browsers
import "core-js/stable"
// Supports asynchronous JS features (inc. async/await)
import "regenerator-runtime/runtime"

// Import typefaces required by @theconversation/ui.
import "typeface-libre-baskerville"
import "typeface-montserrat"
import "typeface-noto-sans"

import ErrorTrack from "lib/ErrorTrack"
import FollowupForm from "containers/FollowupForm"
import DonationFormManager from "containers/DonationFormManager"
import StripeProvider from "containers/StripeProvider"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import HeroComponent from "components/HeroComponent"
import React from "react"
import ReactDOM from "react-dom"
import doFetch from "lib/doFetch"
import domready from "domready"
import i18n from "lib/i18n"
import Presets from "lib/Presets"
import TcDonateGateway from "lib/TcDonateGateway"
// Use a form of loadStripe() which allows us to wait till Stripe is loaded -
// https://github.com/stripe/stripe-js#importing-loadstripe-without-side-effects
import { loadStripe } from "@stripe/stripe-js/pure"

// This is actually our library, wrapping their Ampli library which wraps their Amplitude library..
import { AmplitudeContext } from "contexts/AmplitudeContext"
import { amplitudeClient } from "lib/AmplitudeUtils/ampliBuilder"
import { trackPageView } from "lib/AmplitudeUtils/trackPageView"

// We need these polyfills to support the use of FormData on IE 9 and lower
require("formdata-polyfill")
require("weakmap-polyfill")

i18n.init({
  fallbackLng: "en",
  resources: Config.i18n_resources,
  lng: Config.i18n_locale,
})

domready(() => {
  ErrorTrack.start(BugsnagJsConfig)

  // Initialises the Amplitude client and sends a page view event.
  // The client is later used as the content of an AmplitudeContext
  const ampliClient = amplitudeClient(
    Config.amplitude_enabled,
    Config.amplitude_api_key,
    Config.cookie_consent_required,
  )
  trackPageView(ampliClient, Config.region_code)

  const heroComponent = document.getElementById("hero-component")

  if (heroComponent) {
    const heroElement = React.createElement(HeroComponent, {
      config: Config,
      regions: Regions,
      transactionId: Subscription && Subscription.id,
    })

    ReactDOM.render(heroElement, heroComponent)
  }

  const donationForm = document.getElementById("donation-form")

  if (donationForm) {
    const presets = new Presets(document.location)

    const tcDonateGateway = new TcDonateGateway({
      authenticityToken: AuthenticityToken,
      basePath: `/${Config.region_code}`,
      doFetch,
      region: Config.region_code,
    })
    const donationElement = React.createElement(DonationFormManager, {
      config: Config,
      tcDonateGateway,
      url: DonationsUrl,
      presets,
    })
    const payPalScriptProvider = React.createElement(
      PayPalScriptProvider,
      {
        options: {
          "client-id": Config.Paypal_client_id,
          currency: Config.region_currency_code.toUpperCase(),
          components: "buttons",
          vault: true,
        },
      },
      donationElement,
    )

    const ampliContextProvider = React.createElement(
      AmplitudeContext.Provider,
      { value: ampliClient },
      payPalScriptProvider,
    )

    const loadForm = (stripe) => {
      const stripeProvider = React.createElement(StripeProvider, { stripe }, ampliContextProvider)
      ReactDOM.render(stripeProvider, donationForm)
    }
    // If this region is configured to use Stripe, wait till we can load the
    // Stripe JS library before rendering the form. Otherwise, render the form
    // without Stripe
    if (Config.Stripe_PublishableKey) {
      loadStripe(Config.Stripe_PublishableKey)
        .then(loadForm)
        .catch((reason) => {
          ErrorTrack.notify(
            new Error("Failed to load stripe.js. Will not render credit card inputs", {
              cause: reason,
            }),
            "warning",
          )
          loadForm(null)
        })
    } else {
      loadForm(null)
    }
  }

  const followupForm = document.getElementById("followup-form-wrapper")
  if (followupForm) {
    const followupElement = React.createElement(FollowupForm, {
      authenticityToken: AuthenticityToken,
      authToken: AuthToken,
      config: Config,
      subscription: Subscription,
      url: SubscriptionUrl,
    })
    ReactDOM.render(followupElement, followupForm)
  }

  const hasPlaceholderSupport = () => {
    const input = document.createElement("input")
    return "placeholder" in input
  }

  if (!hasPlaceholderSupport()) {
    const inputs = document.getElementsByTagName("input")
    let i = 0
    const count = inputs.length
    while (i < count) {
      if (inputs[i].getAttribute("placeholder")) {
        inputs[i].style.cssText = "color:#939393;font-style:italic;"
        inputs[i].value = inputs[i].getAttribute("placeholder")

        inputs[i].onclick = () => {
          if (this.value === this.getAttribute("placeholder")) {
            this.value = ""
            this.style.cssText = "color:#000;font-style:normal;"
          }
        }

        inputs[i].onblur = () => {
          if (this.value === "") {
            this.value = this.getAttribute("placeholder")
            this.style.cssText = "color:#939393;font-style:italic;"
          }
        }
      }

      i += 1
    }
  }

  // Set up flash notices (following main TC app)
  let flashRemover = null

  const removeFlash = () => {
    const elements = document.querySelectorAll("p.flash")
    Array.from(elements).forEach((el) => {
      el.style.top = "-200px"
    })
  }

  const showFlash = () => {
    const elements = document.querySelectorAll("p.flash")
    Array.from(elements).forEach((el) => {
      el.style.top = "0px"
      el.addEventListener("click", removeFlash)
    })

    if (flashRemover != null) {
      clearTimeout(flashRemover)
    }
    flashRemover = setTimeout(removeFlash, 5000)
  }

  if (!document.querySelectorAll(".online-donation")[0]) {
    return
  }

  const button = document.getElementById("submit-button")

  // I don't really know what this is doing, but it's causing errors
  // because it's assuming that button has a value...
  if (button) {
    button.disabled = false
  }

  hasPlaceholderSupport()
  showFlash()
})
