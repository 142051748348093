import i18n from "../i18n"
import en from "./Countries.en"
import es from "./Countries.es"
import fr from "./Countries.fr"

const COUNTRIES = { en, es, fr }

let defaultLanguage

const Countries = (language) => {
  if (!defaultLanguage) {
    defaultLanguage = i18n.firstAvailableLanguage(Object.keys(COUNTRIES))
  }
  return COUNTRIES[language] || COUNTRIES[defaultLanguage]
}

export default Countries
