import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button, Typography, withStyles } from "@theconversation/ui"
import { default as Modal } from "@material-ui/core/Modal"
import i18n from "lib/i18n"

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
})

const GiftAidModal = (props) => {
  const { classes, onConfirm, onReject, open } = props

  return (
    <Modal aria-labelledby="gift-aid-modal-title" open={open} onClose={onConfirm}>
      <div className={classNames(classes.paper, "text-center")}>
        <Typography id="gift-aid-modal-title" variant="h6">
          {i18n.t("donation.modal.gift_aid.title")}
        </Typography>
        <Typography variant="subtitle1">{i18n.t("donation.modal.gift_aid.subtitle")}</Typography>
        <Typography variant="subtitle2">
          <p>{i18n.t("donation.modal.gift_aid.body_1")}</p>
          <p>{i18n.t("donation.modal.gift_aid.body_2")}</p>
          <p>{i18n.t("donation.modal.gift_aid.body_3")}</p>
        </Typography>
        <p>
          <Button colour="primary" onClick={onConfirm}>
            <Typography variant="subtitle2">
              <span className="text-sm font-normal cursor-pointer">
                {i18n.t("donation.modal.gift_aid.button.confirm")}
              </span>
            </Typography>
          </Button>
        </p>
        <p>
          <button className="border-0 bg-white ml-1 pl-0" onClick={onReject}>
            <Typography variant="subtitle2">
              <span className="text-sm font-normal cursor-pointer">
                {i18n.t("donation.modal.gift_aid.button.reject")}
              </span>
            </Typography>
          </button>
        </p>
      </div>
    </Modal>
  )
}

GiftAidModal.defaultProps = {
  open: false,
}

GiftAidModal.propTypes = {
  classes: PropTypes.shape({ paper: PropTypes.string }),
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
}
export default withStyles(styles)(GiftAidModal)
