import React from "react"
import PropTypes from "prop-types"

const FormFieldset = ({ children }) => (
  <fieldset style={{ marginBottom: "20px" }}>{children}</fieldset>
)

FormFieldset.defaultProps = {
  children: [],
}

FormFieldset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default FormFieldset
