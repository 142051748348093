import React, { useCallback } from "react"
import PropTypes from "prop-types"

import Analytics from "lib/Analytics"
import errorsPropType from "components/PropTypes/errorsPropType"
import FormFieldset from "components/FormFieldset"
import i18n from "lib/i18n"
import { IbanElement } from "@stripe/react-stripe-js"
import { isEqual } from "underscore"
import StripeInput from "components/StripeInput"
import { TextField } from "@theconversation/ui"

const SepaDirectDebitDetails = ({ active, errors, iban, onChange }) => {
  const handleChange = useCallback((event) => onChange({ iban: event.complete }), [onChange])

  const track = useCallback(() => {
    if (iban) {
      Analytics.trackFormEvent("Donation Form", "Completed", "IBAN")
    } else {
      Analytics.trackFormEvent("Donation Form", "Skipped", "IBAN")
    }
  }, [iban])

  return (
    <React.Fragment>
      <TextField
        disabled={!active}
        error={!!errors.iban}
        fullWidth
        helperText={errors.iban}
        id="donation_iban"
        inputComponent={StripeInput}
        inputProps={{
          component: IbanElement,
          options: { supportedCountries: ["SEPA"] },
        }}
        label={i18n.t("donation.label.iban")}
        onBlur={track}
        onChange={handleChange}
      />
      <FormFieldset>
        <div>{i18n.t("donation.hint.sepa_acceptance")}</div>
      </FormFieldset>
    </React.Fragment>
  )
}

SepaDirectDebitDetails.defaultProps = {
  active: false,
  errors: {},
  iban: false,
  onChange: () => {},
}
SepaDirectDebitDetails.propTypes = {
  active: PropTypes.bool,
  errors: errorsPropType,
  iban: PropTypes.bool,
  onChange: PropTypes.func,
}

export default React.memo(SepaDirectDebitDetails, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
)
