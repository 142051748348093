import ErrorTrack from "lib/ErrorTrack"
import { HTTPError } from "lib/doFetch"

/**
 * Wraps a ProgressEvent so that we can pass it to ErrorTrack with impunity.
 *
 * When a request to `fetch` is blocked, e.g. by AdBlockPlus, it throws a
 * ProgressEvent. If we pass this directly to ErrorTrack.notify,
 * Bugsnag complains because it is not derived from Error. So, we wrap the
 * ProgressEvent in this error and add an informative error message to help
 * us diagnose which URLs donors are blocking.
 * @see https://app.bugsnag.com/the-conversation/frontend-tc-donations/errors/5fbf2b4432e7b70018d3e03e
 */
export class BlockedRequestError extends Error {
  constructor(path, progressEvent) {
    super(`Request to ${path} was blocked`)
    this.path = path
    this.progressEvent = progressEvent
    this.name = this.constructor.name
  }
}

/**
 * Given a caught error, re-throw it after:
 * - passing it to `ErrorTrack.notify()`, if appropriate
 * - wrapping it in / converting it to a more informative error object, if appropriate
 * @param error the error object
 * @param fullPath the full URL we've just attempted to fetch from
 * @return {Error|BlockedRequestError} the error object, or a more informative error object
 */
const processError = (error, fullPath) => {
  let processedError = error
  if (error instanceof ProgressEvent) {
    processedError = new BlockedRequestError(fullPath, error)
  }
  // Unprocessable entity errors (HTTP errors with status 422) result from incorrect auth tokens,
  // which are not something we can generally fix. Notify Bugsnag of any other type of error
  if (!(error instanceof HTTPError && error.response.status === 422)) {
    ErrorTrack.notify(processedError)
  }
  return processedError
}

/**
 * A gateway for calling endpoints on our server (TC-Donate)
 */
export default class TcDonateGateway {
  /**
   * Configures the gateway
   * @param {String} authenticityToken the current Rails authenticity token, to be passed in with calls to the server
   * @param {String} basePath e.g. "/au"
   * @param {Function} doFetch a function with the signature of `fetch()`, used to make HTTP calls.
   * Defaults to `doFetch()
   * @param {String} region the region parameter, to be passed in with calls to the server
   */
  constructor({ authenticityToken, basePath, doFetch, region }) {
    this.authenticityToken = authenticityToken
    this.basePath = basePath
    this.doFetch = doFetch
    this.region = region
  }

  /**
   * Calls a JSON endpoint on our server. Wraps a call to JS's `fetch` method -
   * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API.
   *   n.b. the expected return value is a JSON object with a `success` attribute - if `success` is
   *   true, the call succeeded and we return the object. If false, it failed, and we throw it
   * @param path e.g. "donations.json"
   * @param method valid methods for `fetch`, e.g. "POST"
   * @param data an object to be turned into a JSON payload
   * @param basePath an optional base path to override the default
   * @return {Promise<unknown>}
   */
  async call(path, method, data, basePath = undefined) {
    const body = JSON.stringify(
      TcDonateGateway.underscoreParams({
        utf8: "✓",
        authenticityToken: this.authenticityToken,
        region: this.region,
        ...data,
      }),
    )
    const headers = { "content-type": "application/json" }
    const fullPath = `${basePath || this.basePath}/${path}`

    let response
    try {
      response = await this.doFetch(fullPath, { method, headers, body })
    } catch (error) {
      throw processError(error, fullPath)
    }
    if (response.success) {
      return response
    } else {
      throw response
    }
  }

  /**
   * Returns a copy of `object` with all its camel-case keys converted to
   *   underscore case, recursively.
   * @param object
   * @return {*}
   * @see https://stackoverflow.com/questions/30521224/javascript-convert-pascalcase-to-underscore-case
   */
  static underscoreParams(object) {
    const output = {}
    let underscoredKey
    Object.entries(object).forEach(([key, value]) => {
      let newValue = value
      if (newValue && typeof newValue === "object" && !Array.isArray(newValue)) {
        newValue = TcDonateGateway.underscoreParams(newValue)
      }
      underscoredKey = key
        .replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
        .replace(/^_/, "")
      output[underscoredKey] = newValue
    })
    return output
  }
}
