import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"
import StripeProcessor from "lib/StripeProcessor"

export default class CreditCardProcessor extends StripeProcessor {
  /**
   * Creates a `CreditCardProcessor`
   * @param stripe a reference to the Stripe JS SDK - https://stripe.com/docs/stripe-js
   * @param elements a reference to the Stripe Elements object in the Stripe JS SDK
   * @param donationForm a React ref to the donation form
   * @param tcDonateGateway an instance of `TcDonateGateway`, used to call the TC-Donate backend
   * @param allowed true if Stripe is configured for the current region
   */
  constructor({ stripe, elements, donationForm, tcDonateGateway, allowed }) {
    super({ stripe, elements, donationForm, tcDonateGateway })

    this.allowed = allowed
  }

  /**
   * Indicates whether credit card payment via Stripe is available.
   * @return {boolean} True when both `allowed` and `stripe` are truthy
   */
  get available() {
    return !!(this.allowed && this.stripe)
  }

  /**
   * Exposes the Stripe CardCvcElement
   * @return {null|*}
   */
  get cardCvcElement() {
    if (this.elements) {
      return this.elements.getElement(CardCvcElement)
    }
    return null
  }

  /**
   * Exposes the Stripe CardExpiryElement
   * @return {null|*}
   */
  get cardExpiryElement() {
    if (this.elements) {
      return this.elements.getElement(CardExpiryElement)
    }
    return null
  }

  /**
   * Exposes the Stripe CardNumberElement
   * @return {null|*}
   */
  get cardNumberElement() {
    if (this.elements) {
      return this.elements.getElement(CardNumberElement)
    }
    return null
  }

  /**
   * Enables or disables all Stripe Elements (card number, expiry and CVV inputs)
   * @param enabled If true, enable all elements; if false, disable all
   */
  enableElements(enabled) {
    const disabled = !enabled
    if (this.cardNumberElement) {
      this.cardNumberElement.update({ disabled })
    }
    if (this.cardExpiryElement) {
      this.cardExpiryElement.update({ disabled })
    }
    if (this.cardCvcElement) {
      this.cardCvcElement.update({ disabled })
    }
  }

  /**
   * Wraps a Stripe JS API call to createPaymentMethod()
   * passing in some defaults and also billing details. We expect to be creating
   * a credit card method, hence type: "card" and the card number element.
   * @see https://stripe.com/docs/js/payment_methods/create_payment_method
   * @return {Promise<{paymentMethod?: PaymentMethod; error?: StripeError}> | Error}
   */
  createPaymentMethod() {
    return this.stripe.createPaymentMethod({
      type: "card",
      card: this.cardNumberElement,
      billing_details: this.billingDetails,
    })
  }

  /**
   * Wraps a Stripe JS API call to confirmCardPayment(). Used by StripeOnceOffFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_card_payment
   * @param clientSecret
   * @return {*}
   */
  confirmOnceOff({ clientSecret }) {
    return this.stripe.confirmCardPayment(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to confirmCardPayment(). Used by StripeRecurringFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_card_payment
   * @param clientSecret
   * @return {*}
   */
  confirmRecurring({ clientSecret }) {
    return this.stripe.confirmCardPayment(clientSecret)
  }
}
