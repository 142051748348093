import DirectDebitProcessor from "lib/DirectDebitProcessor"
import { IbanElement } from "@stripe/react-stripe-js"

/**
 * SEPA direct debit payment processor.
 */
export default class SepaDirectDebitProcessor extends DirectDebitProcessor {
  /**
   * Wraps a Stripe JS API call to confirmSepaDebitPayment(). Used by StripeOnceOffFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_sepa_debit_payment
   * @param clientSecret a payment intent client secret
   * @return {*}
   */
  confirmOnceOff({ clientSecret }) {
    return this.stripe.confirmSepaDebitPayment(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to confirmSepaDebitSetup(). Used by StripeRecurringFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_sepa_debit_payment
   * @param clientSecret a payment intent client secret
   * @return {*}
   */
  confirmRecurring({ clientSecret }) {
    return this.stripe.confirmSepaDebitSetup(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to createPaymentMethod()
   * passing in some defaults and also billing details. We expect to be creating
   * a SEPA direct debit method, hence type: "sepa_debit" and the IBAN element.
   * @see https://stripe.com/docs/js/payment_methods/create_payment_method
   * @return {Promise<{paymentMethod?: PaymentMethod; error?: StripeError}> | Error}
   */
  createPaymentMethod() {
    return this.stripe.createPaymentMethod({
      billing_details: this.billingDetails,
      sepa_debit: this.ibanElement,
      type: "sepa_debit",
    })
  }

  /**
   * @return {string} the default field to add any errors to, e.g. "cardNumber"
   */
  // eslint-disable-next-line class-methods-use-this
  get defaultErrorField() {
    return "iban"
  }

  /**
   * Enables or disables the IBAN element
   * @param enabled If true, enable the IBAN element; if false, disable it
   */
  enableElements(enabled) {
    const disabled = !enabled
    if (this.ibanElement) {
      this.ibanElement.update({ disabled })
    }
  }

  /**
   * Exposes the Stripe IbanElement
   * @return {null|*}
   */
  get ibanElement() {
    if (this.elements) {
      return this.elements.getElement(IbanElement)
    }
    return null
  }
}
