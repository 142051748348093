import React, { useCallback } from "react"
import PropTypes from "prop-types"
import errorsPropType from "components/PropTypes/errorsPropType"

import { isEqual } from "underscore"

import TextField from "components/MemoisedTextField"

import CountrySelect from "components/CountrySelect"
import FormFieldset from "components/FormFieldset"

import Analytics from "lib/Analytics"
import i18n from "lib/i18n"

// Analytics tracking
const track = (field) => (event) => {
  const value = event.target.value
  if (value.length) {
    Analytics.trackFormEvent("Donation Form", "Completed", field)
  } else {
    Analytics.trackFormEvent("Donation Form", "Skipped", field)
  }
}

const AddressDetails = ({
  active,
  address,
  city,
  country,
  errors,
  giftAid,
  homeCountryCode,
  objectName,
  onChange,
  postcode,
  state,
}) => {
  const trackAddress = useCallback((event) => track("Address")(event), [])
  const trackCity = useCallback((event) => track("City")(event), [])
  const trackState = useCallback((event) => track("State")(event), [])
  const trackCountry = useCallback((event) => track("Country")(event), [])
  const trackPostcode = useCallback((event) => track("Postcode")(event), [])

  // Change handling
  const handleChange = useCallback(
    (field) => (event) => onChange({ [field]: event.target.value }),
    [onChange],
  )

  const setAddress = useCallback((event) => handleChange("address")(event), [handleChange])
  const setCity = useCallback((event) => handleChange("city")(event), [handleChange])
  const setCountryDetails = useCallback((event) => onChange(event), [onChange])
  const setPostcode = useCallback((event) => handleChange("postcode")(event), [handleChange])
  const setState = useCallback((event) => handleChange("state")(event), [handleChange])

  const giftAidHint = giftAid ? i18n.t("donation.hint.gift_aid_required_field") : ""

  return (
    <div>
      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.address}
          fullWidth
          label={i18n.t("donation.label.home_address")}
          helperText={errors.address || giftAidHint}
          id={`${objectName}_address`}
          name={`${objectName}[address]`}
          onBlur={trackAddress}
          onChange={setAddress}
          type="text"
          value={address}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.city}
          fullWidth
          helperText={errors.city}
          id={`${objectName}_city`}
          label={i18n.t("donation.label.city")}
          name={`${objectName}[city]`}
          onBlur={trackCity}
          onChange={setCity}
          type="text"
          value={city}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.state}
          fullWidth
          label={i18n.t("donation.label.state")}
          helperText={errors.state}
          id={`${objectName}_state`}
          name={`${objectName}[state]`}
          onBlur={trackState}
          onChange={setState}
          type="text"
          value={state}
        />
      </FormFieldset>

      <FormFieldset>
        <CountrySelect
          active={active}
          defaultCountryCode={homeCountryCode}
          error={!!errors.country}
          helperText={errors.country}
          id={`${objectName}_country`}
          name={`${objectName}[country]`}
          onBlur={trackCountry}
          onChange={setCountryDetails}
          value={country}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          autoComplete
          disabled={!active}
          error={!!errors.postcode}
          fullWidth
          label={i18n.t("donation.label.postcode")}
          helperText={errors.postcode || giftAidHint}
          id={`${objectName}_postcode`}
          name={`${objectName}[postcode]`}
          onBlur={trackPostcode}
          onChange={setPostcode}
          type="text"
          value={postcode}
        />
      </FormFieldset>
    </div>
  )
}

AddressDetails.defaultProps = {
  active: true,
  address: "",
  city: "",
  country: "",
  errors: {},
  giftAid: false,
  homeCountryCode: "AU",
  objectName: "donation",
  onChange: () => {},
  postcode: "",
  state: "",
}

AddressDetails.propTypes = {
  active: PropTypes.bool,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  errors: errorsPropType,
  giftAid: PropTypes.bool,
  homeCountryCode: PropTypes.string,
  objectName: PropTypes.string,
  onChange: PropTypes.func,
  postcode: PropTypes.string,
  state: PropTypes.string,
}

export default React.memo(AddressDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps))
