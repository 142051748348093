import { ampli } from "lib/ampli"

/**
 * Builds a genuine Ampli client, using an API key.
 * The result of this function could be passed as a value prop for AmplitudeContext.Provider.
 *
 * @param {string} apiKey An Amplitude API key
 */
function buildAmpliClient(apiKey) {
  ampli.load({
    client: {
      apiKey: apiKey,
      configuration: {
        defaultTracking: {
          attribution: {
            // If left on default, Amplitude excludes attribution coming from referrers
            // that match your own top-level domain. (ie. from theconversation to donate.)
            // We set this to empty to include all sources.
            excludeReferrers: [],
            resetSessionOnNewCampaign: true,
          },
          sessions: true,
          pageViews: false,
          formInteractions: false,
          fileDownloads: false,
        },
      },
    },
  })
  return ampli
}

// This generates a dummy client which does not contact the Amplitude API.
// It's main use-case is to be the default value passed to the AmplitudeContext.
// In practice it shouldn't be called -- and will log to console in non-prod
// environments if it is called.
export function buildNoOpClient() {
  function defaultClientWarning(functionName) {
    if (!window.location.hostname.endsWith("theconversation.com")) {
      console.log(`Stub Ampli client called with ${functionName}()`)
    }
    return ""
  }
  const functionsToStub = ["track", "donationSubmitted", "donationReceived", "pageViewed"]

  var fakeClient = Object.fromEntries(
    functionsToStub.map((n) => [n, () => defaultClientWarning(n)]),
  )

  fakeClient["client"] = {
    getDeviceId: () => defaultClientWarning("client.getDeviceId()"),
  }

  return fakeClient
}

/**
 * Fetch amplitude client. Chooses whether to initialise a real client or a fixture,
 * it depending on provided configuration values for feature enablement and cookie
 * consent requirements.
 *
 * @param {boolean} amplitude_enabled Is Amplitude configured to be enabled?
 * @param {string} amplitude_api_key The API key to use, if amplitude is initialised.
 * @param {boolean} cookie_consent_required Is cookie consent required in this region?
 */
export function amplitudeClient(amplitude_enabled, amplitude_api_key, cookie_consent_required) {
  // Initially, we will only launch the amplitude client in regions that do not require
  // cookie consent.
  if (amplitude_enabled && !cookie_consent_required) {
    return buildAmpliClient(amplitude_api_key)
  } else {
    return buildNoOpClient()
  }
}
