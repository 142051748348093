/* eslint-disable react/prop-types */
/* level and children in our components are incorrectly inferred by eslint as props of <Markdown> */

import React from "react"
import ReactMarkdown from "react-markdown"
import { Typography } from "@theconversation/ui"

// Adapted from:
// https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/blog/Markdown.js

const Header = ({ level, children }) => {
  const headerVariant = `h${level}`

  return <Typography variant={headerVariant}>{children}</Typography>
}

const components = {
  h1: (props) => <Header {...props} />,
  h2: (props) => <Header {...props} />,
  h3: (props) => <Header {...props} />,
  h4: (props) => <Header {...props} />,
  h5: (props) => <Header {...props} />,
  h6: (props) => <Header {...props} />,

  p: ({ children }) => <Typography paragraph>{children}</Typography>,
}

export default function Markdown(props) {
  return <ReactMarkdown components={components} {...props} />
}
