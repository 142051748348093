import classNames from "classnames/bind"
import React from "react"
import PropTypes from "prop-types"
import { Button, Typography, withStyles } from "@theconversation/ui"
import styles from "./styles.scss"

const classNamesMapping = classNames.bind({
  buttonSelected: styles.on,
  ...styles,
})

const AmountButton = ({ active, width, selected, label, secondaryLabel, onClick, children }) => {
  const labelStyle = {
    extended: !!secondaryLabel,
  }

  return (
    <div style={{ width }} className={styles.container}>
      <Button
        disabled={!active}
        fullWidth
        className={classNamesMapping("button", { buttonSelected: selected })}
        color={selected ? "primary" : "secondary"}
        onClick={onClick}
        variant="text"
      >
        <Typography className={classNamesMapping("label", labelStyle)}>{label}</Typography>
        {children}
        {secondaryLabel && (
          <Typography className={classNamesMapping("label", "secondaryLabel", labelStyle)}>
            {secondaryLabel}
          </Typography>
        )}
      </Button>
    </div>
  )
}

AmountButton.defaultProps = {
  active: true,
  children: undefined,
  label: "",
  secondaryLabel: undefined,
  onClick: () => {},
  selected: false,
  width: "20%",
}

AmountButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondaryLabel: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  width: PropTypes.string,
}

export default withStyles(styles)(AmountButton)
