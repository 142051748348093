// Queues up a page view tracking event.
export function trackPageView(client, edition) {
  const location = window.location

  const event = {
    app: "donations",
    edition: edition,
    page_type: "donation",
    path: location.pathname,
  }

  const utmTags = gatherUtmTags(location)

  client.pageViewed({ ...event, ...utmTags })
}

// Gathers UTM tags from the URL query parameters, if present
function gatherUtmTags(location) {
  const params = new URL(location).searchParams
  const utmParamNames = ["utm_campaign", "utm_content", "utm_medium", "utm_source"]
  var utmResults = {}
  utmParamNames.forEach((utm) => {
    const v = params?.get(utm)
    if (v != null && v != undefined) {
      utmResults[utm] = v
    }
  })
  return utmResults
}
