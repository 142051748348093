import React, { useCallback } from "react"
import PropTypes from "prop-types"
import errorsPropType from "components/PropTypes/errorsPropType"
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"
import { TextField, withStyles } from "@theconversation/ui"
import { isEqual } from "underscore"
import FormFieldset from "components/FormFieldset"
import StripeInput from "components/StripeInput"
import Analytics from "lib/Analytics"
import i18n from "lib/i18n"

const styles = {
  ccDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  expiryContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 20,
  },
  expiry: {
    width: 90,
    marginRight: 4,
  },
  cvv: {
    float: "right",
    width: 90,
  },
}

const CreditCardDetails = (props) => {
  const { classes, errors, fieldsActive, onChange } = props

  const track = useCallback(
    (label, propName) => {
      if (props[propName]) {
        Analytics.trackFormEvent("Donation Form", "Completed", label)
      } else {
        Analytics.trackFormEvent("Donation Form", "Skipped", label)
      }
    },
    [props],
  )

  const trackCardNumber = useCallback(() => track("Credit Card Number", "cardNumber"), [track])
  const trackExpiry = useCallback(() => track("Expiry", "expiry"), [track])
  const trackCVV = useCallback(() => track("CVV", "cvv"), [track])

  const handleChange = useCallback(
    (field) => (event) => onChange({ [field]: event.complete }),
    [onChange],
  )

  const setCardNumber = useCallback((event) => handleChange("cardNumber")(event), [handleChange])
  const setExpiry = useCallback((event) => handleChange("expiry")(event), [handleChange])
  const setCvv = useCallback((event) => handleChange("cvv")(event), [handleChange])

  return (
    <div id="credit-card">
      <FormFieldset>
        <div>
          <TextField
            disabled={!fieldsActive}
            error={!!errors.cardNumber}
            fullWidth
            helperText={errors.cardNumber}
            id="donation_cc_number"
            inputComponent={StripeInput}
            inputProps={{ component: CardNumberElement }}
            label={i18n.t("donation.label.card_number")}
            onBlur={trackCardNumber}
            onChange={setCardNumber}
          />
        </div>
      </FormFieldset>

      <FormFieldset>
        <div className={classes.ccDetails}>
          <div className={classes.expiryContainer}>
            <TextField
              className={classes.expiry}
              disabled={!fieldsActive}
              error={!!errors.expiry}
              helperText={errors.expiry}
              id="donation_cc_expiry"
              inputComponent={StripeInput}
              inputProps={{ component: CardExpiryElement }}
              label={i18n.t("donation.label.expiry")}
              onBlur={trackExpiry}
              onChange={setExpiry}
              placeholder={i18n.t("donation.placeholder.expiry")}
            />
          </div>

          <TextField
            className={classes.cvv}
            disabled={!fieldsActive}
            error={!!errors.cvv}
            helperText={errors.cvv}
            id="donation_cc_verification"
            inputComponent={StripeInput}
            inputProps={{ component: CardCvcElement }}
            label={i18n.t("donation.label.cvv")}
            onBlur={trackCVV}
            onChange={setCvv}
            placeholder=""
          />
        </div>
      </FormFieldset>
    </div>
  )
}

CreditCardDetails.defaultProps = {
  cardNumber: false,
  classes: {
    ccDetails: "",
    expiryContainer: "",
    expiry: "",
    cvv: "",
  },
  cvv: false,
  errors: {
    cardNumber: "",
    cvv: "",
    month: "",
    year: "",
  },
  expiry: false,
  fieldsActive: false,
  month: "",
  year: "",
  onChange: () => {},
}

CreditCardDetails.propTypes = {
  classes: PropTypes.shape({
    ccDetails: PropTypes.string,
    expiryContainer: PropTypes.string,
    expiry: PropTypes.string,
    cvv: PropTypes.string,
  }),
  errors: errorsPropType,
  fieldsActive: PropTypes.bool,
  onChange: PropTypes.func,

  // The fields below are not referenced by name (hence the eslint-disable), but are used by
  // track() via props[propName] to instrument the user's interaction with the form.
  // Stripe won't tell us these values, just whether they are complete.
  /* eslint-disable react/no-unused-prop-types */
  cardNumber: PropTypes.bool,
  cvv: PropTypes.bool,
  expiry: PropTypes.bool,
  /* eslint-enable react/no-unused-prop-types */
}

export default withStyles(styles)(
  React.memo(CreditCardDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps)),
)
