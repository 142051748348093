import i18n from "lib/i18n"
import React from "react"
import PropTypes from "prop-types"
import FormFieldset from "components/FormFieldset"
import { FormControlLabel, Checkbox } from "@theconversation/ui"

// The PubliclyRecogniseDonor shows the user an opt-in
// for the donation to be publicly recognised, e.g:
// - Shown on TC's Friends of The Conversation page
// - Given a FoTC badge on their profile

// Internally (in the DB/DW/TC), we store the opposite; if a donation
// is meant to be hidden from the public (or anonymous).

// We set a value of `false` because, if a donation is publicly
// recognised, it's not anonymous.

const PubliclyRecogniseDonor = ({ active, onChange, publiclyRecogniseDonor, formName }) => (
  <FormFieldset>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={publiclyRecogniseDonor}
          disabled={!active}
          id={`${formName}_anonymous`}
          name={`${formName}[anonymous]`}
          onChange={onChange}
          value="false"
        />
      }
      label={i18n.t("donation.label.publicly_recognise_donor")}
    />
  </FormFieldset>
)

export default React.memo(PubliclyRecogniseDonor)

PubliclyRecogniseDonor.defaultProps = {
  active: false,
  onChange: () => {},
  publiclyRecogniseDonor: false,
  formName: "donation",
}

PubliclyRecogniseDonor.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  publiclyRecogniseDonor: PropTypes.bool,
  formName: PropTypes.string,
}
