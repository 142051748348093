import React, { useCallback } from "react"
import PropTypes from "prop-types"
import FormFieldset from "components/FormFieldset"
import GiftAidConfirmationModal from "components/GiftAidConfirmationModal"
import GiftAidTooltip from "components/GiftAidTooltip"
import { FormControlLabel, Checkbox } from "@theconversation/ui"
import i18n from "lib/i18n"

const GiftAidCheckbox = (props) => {
  const { checked, onChange } = props
  const [modalOpen, setModalOpen] = React.useState(false)

  const modalConfirm = useCallback(() => {
    onChange({ giftAid: true })
    setModalOpen(false)
  }, [onChange, setModalOpen])
  const modalReject = useCallback(() => {
    onChange({ giftAid: false })
    setModalOpen(false)
  }, [onChange, setModalOpen])
  // Only open the confirmation modal when the checkbox is checked. We need confirmation
  // when the donor claims Gift Aid, not when they opt not to.
  const setGiftAid = useCallback(
    (event) => {
      onChange({ giftAid: event.target.checked })
      if (event.target.checked) {
        setModalOpen(true)
      }
    },
    [onChange, setModalOpen],
  )

  return (
    <>
      <FormFieldset>
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={checked} onChange={setGiftAid} value="true" />
          }
          label={
            <>
              {i18n.t("donation.label.gift_aid_checkbox")}
              <GiftAidTooltip />
            </>
          }
        />
      </FormFieldset>
      <GiftAidConfirmationModal open={modalOpen} onConfirm={modalConfirm} onReject={modalReject} />
    </>
  )
}

GiftAidCheckbox.defaultProps = {
  checked: false,
  onChange: () => {},
}

GiftAidCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}
export default React.memo(GiftAidCheckbox)
