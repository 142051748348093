import React from "react"
import PropTypes from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import StripeProps from "components/PropTypes/StripeProps"

const StripeProvider = ({ stripe, children }) => <Elements stripe={stripe}>{children}</Elements>

StripeProvider.defaultProps = {
  stripe: null,
}

StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  stripe: PropTypes.oneOfType([StripeProps, PropTypes.instanceOf(Promise)]),
}

export default StripeProvider
