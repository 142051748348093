import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider, Typography } from "@theconversation/ui"
import Markdown from "components/Markdown"
import AddressDetails from "components/AddressDetails"
import donationsTheme from "components/donationsTheme"
import FormError from "components/FormError"
import FormFieldset from "components/FormFieldset"
import PubliclyRecogniseDonor from "components/PubliclyRecogniseDonor"
import SubmitButton from "components/SubmitButton"
import TaxId from "components/TaxId"
import ErrorTrack from "lib/ErrorTrack"
import i18n from "lib/i18n"
import { ConfigProps, ConfigDefaultProps } from "components/PropTypes/ConfigProps"

// Wraps a ProgressEvent raised as an error on an AJAX request
// @see https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/error_event
// @see https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent
class AjaxError extends Error {
  constructor(event) {
    super(event.target.statusText)
    this.name = "AjaxError"
    this.event = event
  }
}

export default class FollowupForm extends React.Component {
  static transformErrors(errors) {
    const transformedErrors = {}
    Object.keys(errors).forEach((key) => {
      const camelizedKey = key.replace(/(_\w)/, (match) => match.substr(1).toUpperCase())
      transformedErrors[camelizedKey] = new Array(errors[key]).join(", ")
    })
    return transformedErrors
  }

  constructor(props) {
    super(props)

    this.state = {
      address: this.props.subscription.address || "",
      city: this.props.subscription.city || "",
      country: this.props.subscription.country || "",
      errors: FollowupForm.transformErrors(this.props.subscription.errors || {}),
      postcode: this.props.subscription.postcode || "",
      state: this.props.subscription.state || "",
      publiclyRecogniseDonor: !this.props.subscription.anonymous || false,
      taxId: this.props.subscription.tax_id || "",
      submitting: false,
      complete: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.onError = this.onError.bind(this)
    this.onLoad = this.onLoad.bind(this)
    this.setAddressDetails = this.setAddressDetails.bind(this)
    this.setRecogniseDonor = this.setRecogniseDonor.bind(this)
    this.setTaxId = this.setTaxId.bind(this)
  }

  onError(event) {
    this.setState({ submitting: false })
    // `event` is a `ProgressEvent`, not an error, so we need to wrap it in an `Error` so Bugsnag
    // knows what to do with it.
    // See https://trello.com/c/DCyaCqZm/10284-invaliderror-in-au-donations-2ad2bc03-3fc4-44fe-b54c-7e7ba4aed5fc-success
    ErrorTrack.notify(new AjaxError(event))
    this.unclassifiedError(event)
  }

  onLoad(e) {
    this.setState({ submitting: false })
    try {
      if (e.target.status >= 400) {
        ErrorTrack.notify(new AjaxError(e))
        this.unclassifiedError(e)
      } else {
        const response = JSON.parse(e.target.responseText)
        if (response.success) {
          this.setState({ complete: true })
        } else {
          this.setState({ errors: FollowupForm.transformErrors(response.errors) })
        }
      }
    } catch (error) {
      ErrorTrack.notify(error)
      this.unclassifiedError(e)
    }
  }

  setAddressDetails(addressDetails) {
    this.setState(addressDetails)
  }

  setRecogniseDonor() {
    this.setAddressDetails({ publiclyRecogniseDonor: !this.state.publiclyRecogniseDonor })
  }

  setTaxId(taxId) {
    this.setState(taxId)
  }

  unclassifiedError() {
    this.setState({ errors: { base: [i18n.t("capture_address.message.unrecoverable_error")] } })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ errors: {} })
    const data = new FormData(this.form)
    const subscriptionId = data.get("subscription[subscription_id]")
    this.setState({ submitting: true }, () => {
      const xhr = new XMLHttpRequest()
      xhr.addEventListener("load", this.onLoad)
      xhr.addEventListener("error", this.onError)
      xhr.open("PUT", this.props.url.replace(":id", subscriptionId))
      xhr.send(data)
    })
  }

  renderForm() {
    if (this.state.complete) {
      return null
    }

    return (
      <form
        acceptCharset="UTF-8"
        action={this.props.url}
        id="followup-form"
        method="post"
        onSubmit={this.handleSubmit}
        ref={(component) => {
          this.form = component
        }}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input name="authenticity_token" type="hidden" value={this.props.authenticityToken} />
        <input name="subscription[auth_token]" type="hidden" value={this.props.authToken} />
        <input
          name="subscription[subscription_id]"
          type="hidden"
          value={this.props.subscription.id}
        />
        <input
          name="subscription[gift_aid]"
          type="hidden"
          value={this.props.subscription.gift_aid}
        />
        <input name="subscription[followup_form]" type="hidden" value="1" />
        <fieldset>
          <FormFieldset>
            <Typography variant="h6">{i18n.t("capture_address.title")}</Typography>
          </FormFieldset>
          {i18n.t("capture_address.blurb") !== "" && <p>{i18n.t("capture_address.blurb")}</p>}
          <FormError error={this.state.errors.base} />

          {this.props.config.show_tax_id && (
            <FormFieldset>
              <TaxId
                active={!this.state.submitting}
                errors={this.state.errors}
                onChange={this.setTaxId}
                taxId={this.state.taxId}
                formName="subscription"
              />
            </FormFieldset>
          )}

          <AddressDetails
            address={this.state.address}
            city={this.state.city}
            country={this.state.country}
            errors={this.state.errors}
            giftAid={this.props.subscription.gift_aid}
            homeCountryCode={this.props.config.region_home_country_code}
            postcode={this.state.postcode}
            state={this.state.state}
            objectName="subscription"
            onChange={this.setAddressDetails}
          />
        </fieldset>

        <FormFieldset>
          <PubliclyRecogniseDonor
            active={!this.state.submitting}
            onChange={this.setRecogniseDonor}
            publiclyRecogniseDonor={this.state.publiclyRecogniseDonor}
            formName="subscription"
          />
        </FormFieldset>

        <SubmitButton
          active={!this.state.submitting}
          text={i18n.t("capture_address.form.update")}
        />
      </form>
    )
  }

  renderSuccessMessage() {
    if (this.state.complete) {
      const date = new Date()
      const month = date.toLocaleString(undefined, { month: "short" })
      const name =
        `${this.props.subscription.first_name} ${this.props.subscription.last_name}`.trim()
      const email = i18n.t("capture_address.success.email")
      const onceOff = this.props.subscription.frequency === "once"
      const advice = onceOff
        ? i18n.t("capture_address.success.receipt_once")
        : i18n.t("capture_address.success.receipt_monthly", { email })

      return (
        <Markdown>
          {i18n.t("capture_address.success.thank_you_message", {
            date: `${month}. ${date.getDate()}, ${date.getFullYear()}`,
            name,
            receipt_advice: advice,
          })}
        </Markdown>
      )
    }

    return null
  }

  render() {
    return (
      <ThemeProvider theme={donationsTheme}>
        {this.renderForm()}
        {this.renderSuccessMessage()}
      </ThemeProvider>
    )
  }
}

FollowupForm.defaultProps = {
  authenticityToken: "",
  authToken: "",
  config: ConfigDefaultProps,
  subscription: {
    errors: {},
  },
  url: "",
}

FollowupForm.propTypes = {
  authenticityToken: PropTypes.string,
  authToken: PropTypes.string,
  config: ConfigProps,
  subscription: PropTypes.shape({
    address: PropTypes.string,
    anonymous: PropTypes.bool,
    city: PropTypes.string,
    country: PropTypes.string,
    errors: PropTypes.object,
    frequency: PropTypes.string,
    gift_aid: PropTypes.bool,
    id: PropTypes.number,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    tax_id: PropTypes.string,
  }),
  url: PropTypes.string,
}
