import DirectDebitProcessor from "lib/DirectDebitProcessor"

/**
 * Null direct debit payment processor, for use when direct debit payment is not available.
 */
export default class NullDirectDebitProcessor extends DirectDebitProcessor {
  /**
   * Indicates that payment via direct debit is not availabe.
   * @return {boolean} always false
   */
  // eslint-disable-next-line class-methods-use-this
  get available() {
    return false
  }
}
