import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button } from "@theconversation/ui"
import watchWindowWidth from "lib/watchWindowWidth"

const SubmitButton = ({ active, text }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => watchWindowWidth(setWindowWidth), [])

  return (
    <Button
      size="large"
      type="submit"
      name="commit"
      disabled={!active}
      id="submit-button"
      autoComplete="off"
      colour="primary"
      fullWidth={windowWidth < 600}
    >
      {text}
    </Button>
  )
}

export default SubmitButton

SubmitButton.defaultProps = {
  active: true,
  text: "",
}

SubmitButton.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
}
