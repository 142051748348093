import i18next from "i18next"

// A wrapper around i18next to allow for i18n related methods to be
// incorporated, make JS specs a little easier, and potentially allow us to
// use the kind of t9n backend management we currently use in TC

export default class i18n {
  static init(...args) {
    i18next.init(...args)
  }
  static t(...args) {
    const translation = i18next.t(...args)
    if (translation || translation === "") {
      return translation
    }
    return args[0]
  }
  // Given a list of languages, return the first of them that is also in
  // i18next.languages. In other words, pick the first language from a list
  // that is available in this context
  static firstAvailableLanguage(languages) {
    return i18next.languages.find((availableLanguage) =>
      languages.find((language) => language === availableLanguage),
    )
  }
}
