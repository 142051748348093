import StripeProcessor from "lib/StripeProcessor"

/**
 * Base class for Stripe direct debit payment processors.
 */
export default class DirectDebitProcessor extends StripeProcessor {
  /**
   * Indicates whether payment via this processor is available.
   * @return {boolean} True when Stripe is available
   */
  get available() {
    return !!this.stripe
  }

  /**
   * Called by the `StripeRecurringFlow` to process a new recurring payment in our back-end and wrap
   * the result so that it is in the form `StripeRecurringFlow` expects. In this case, we expect the
   * back-end to create a setup intent
   * @param donationDetails to be passed through to the backend when
   * submitting the donation. See `DonationFormManager.donationDetails`.
   * @param paymentTokens to be passed through to the back-end
   * @returns {Promise<{clientSecret: string, status: string}}
   */
  async executeRecurring({ donationDetails, paymentTokens }) {
    const result = await this.submitForm({ donationDetails, paymentTokens })
    const { stripe_setup_intent_client_secret: clientSecret, stripe_setup_intent_status: status } =
      result
    return { clientSecret, status }
  }
}
