import React from "react"
import PropTypes from "prop-types"
import { Button, ClickAwayListener, Tooltip, Typography, withStyles } from "@theconversation/ui"
import { default as Fade } from "@material-ui/core/Fade"
import i18n from "lib/i18n"

// `styles` and `arrowGenerator` style the tooltip and its arrow according to the theme. They're
// pinched wholesale from @tiagoamaro's `QuickView` component in TC"
// see https://github.com/conversation/tc/blob/391be07/rails/app/assets/javascripts/components/Editorial/QuickView/index.jsx#L5-L80

/**
 * Adding arrows to MUI tooltips can be tricky as tooltips position are dynamic.
 * The `arrowGenerator` function was extracted from MUI v4.5.2:
 * https://github.com/mui/material-ui/blob/v4.5.2/docs/src/pages/components/tooltips/CustomizedTooltips.js
 */
function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-10px",
      width: "10px",
      height: "10px",
      "&::before": {
        borderWidth: "0 10px 10px 10px",
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-10px",
      width: "10px",
      height: "10px",
      "&::before": {
        borderWidth: "10px 10px 0 10px",
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-10px",
      height: "10px",
      width: "10px",
      "&::before": {
        borderWidth: "10px 10px 10px 0",
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-10px",
      height: "10px",
      width: "10px",
      "&::before": {
        borderWidth: "10px 0 10px 10px",
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  }
}

const styles = (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    boxShadow: "1px 1px 8px 8px rgba(0,0,0,0.15)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 315,
  },
  popper: arrowGenerator(theme.palette.common.white),
  arrow: {
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
})

const ignoreEvent = (event) => event.preventDefault()

const GiftAidTooltip = ({ classes }) => {
  const { arrow, ...tooltipClasses } = classes
  const [open, setOpen] = React.useState(false)
  const [arrowRef, setArrowRef] = React.useState(null)

  const toggleTooltip = (event) => {
    event.preventDefault()
    setOpen(!open)
  }
  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        classes={tooltipClasses}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        onClose={handleTooltipClose}
        open={open}
        placement="bottom"
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(arrowRef),
                element: arrowRef,
              },
            },
          },
        }}
        title={
          // Suppress clicks on the tooltip, so they don't bubble up to the Gift Aid label and cause
          // the Gift Aid checkbox to be toggled unintentionally.
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <div
            className="cursor-default p-2"
            onClick={ignoreEvent}
            onKeyPress={ignoreEvent}
            role="tooltip"
          >
            <Typography variant="subtitle1">
              {i18n.t("donation.tooltip.gift_aid.header")}
            </Typography>
            <Typography variant="subtitle2">{i18n.t("donation.tooltip.gift_aid.body")}</Typography>
            <div className="mt-3">
              <Button colour="primary" onClick={handleTooltipClose}>
                {i18n.t("donation.tooltip.gift_aid.button")}
              </Button>
            </div>
            <span className={arrow} ref={setArrowRef} />
          </div>
        }
        TransitionComponent={Fade}
      >
        <button className="border-0 bg-white underline ml-1 pl-0" onClick={toggleTooltip}>
          <Typography variant="subtitle2">
            <span className="text-sm font-normal cursor-pointer">
              {i18n.t("donation.label.gift_aid_checkbox_hint")}
            </span>
          </Typography>
        </button>
      </Tooltip>
    </ClickAwayListener>
  )
}

GiftAidTooltip.defaultProps = {
  classes: {},
}

GiftAidTooltip.propTypes = {
  classes: PropTypes.shape({
    arrow: PropTypes.string,
    popper: PropTypes.string,
    tooltip: PropTypes.string,
  }),
}

export default withStyles(styles)(GiftAidTooltip)
