import { AuBankAccountElement } from "@stripe/react-stripe-js"
import DirectDebitProcessor from "lib/DirectDebitProcessor"

/**
 * BECS direct debit payment processor.
 */
export default class BecsDirectDebitProcessor extends DirectDebitProcessor {
  /**
   * Exposes the Stripe AuBankAccountElement
   * @return {null|*}
   */
  get auBankAccountElement() {
    if (this.elements) {
      return this.elements.getElement(AuBankAccountElement)
    }
    return null
  }

  /**
   * BECS-specific account details, i.e. with a separate account name
   * @override
   */
  get billingDetails() {
    const { email, accountName: name } = this.donationDetails
    return { email, name }
  }

  /**
   * Wraps a Stripe JS API call to confirmAuBecsDebitPayment(). Used by StripeOnceOffFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_au_becs_debit_payment
   * @param clientSecret a payment intent client secret
   * @return {*}
   */
  confirmOnceOff({ clientSecret }) {
    return this.stripe.confirmAuBecsDebitPayment(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to confirmAuBecsDebitSetup(). Used by StripeRecurringFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_au_becs_debit_payment
   * @param clientSecret a payment intent client secret
   * @return {*}
   */
  confirmRecurring({ clientSecret }) {
    return this.stripe.confirmAuBecsDebitSetup(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to createPaymentMethod()
   * passing in some defaults and also billing details. We expect to be creating
   * a BECS direct debit method, hence type: "becs_debit" and the AU bank
   * account element.
   * @see https://stripe.com/docs/js/payment_methods/create_payment_method
   * @return {Promise<{paymentMethod?: PaymentMethod; error?: StripeError}> | Error}
   */
  createPaymentMethod() {
    return this.stripe.createPaymentMethod({
      au_becs_debit: this.auBankAccountElement,
      billing_details: this.billingDetails,
      type: "au_becs_debit",
    })
  }

  /**
   * @return {string} the default field to add any errors to, e.g. "cardNumber"
   */
  // eslint-disable-next-line class-methods-use-this
  get defaultErrorField() {
    return "bsbAccountNumber"
  }

  /**
   * Enables or disables the bank account element
   * @param enabled If true, enable the bank account element; if false, disable it
   */
  enableElements(enabled) {
    const disabled = !enabled
    if (this.auBankAccountElement) {
      this.auBankAccountElement.update({ disabled })
    }
  }
}
