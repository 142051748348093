import React, { useCallback } from "react"
import PropTypes from "prop-types"

import { AuBankAccountElement } from "@stripe/react-stripe-js"
import errorsPropType from "components/PropTypes/errorsPropType"
import FormFieldset from "components/FormFieldset"
import i18n from "lib/i18n"
import { isEqual } from "underscore"
import StripeInput from "components/StripeInput"
import { TextField } from "@theconversation/ui"
import Analytics from "../../lib/Analytics"

const BecsDirectDebitDetails = (props) => {
  const { accountName, active, errors, onChange } = props

  const handleAccountNameChange = useCallback(
    (event) => onChange({ accountName: event.target.value }),
    [onChange],
  )
  const handleBsbAccountNumberChange = useCallback(
    (event) => onChange({ bsbAccountNumber: event.complete }),
    [onChange],
  )

  const track = useCallback(
    (label, propName) => {
      if (props[propName]) {
        Analytics.trackFormEvent("Donation Form", "Completed", label)
      } else {
        Analytics.trackFormEvent("Donation Form", "Skipped", label)
      }
    },
    [props],
  )

  const trackAccountName = useCallback(() => track("Account Name", "accountName"), [track])
  const trackBsbAccountNumber = useCallback(
    () => track("BSB / Account Number", "bsbAccountNumber"),
    [track],
  )

  return (
    <React.Fragment>
      <FormFieldset>
        <TextField
          disabled={!active}
          error={!!errors.accountName}
          fullWidth
          helperText={errors.accountName}
          id="donation_account_name"
          label={i18n.t("donation.label.account_name")}
          name="donation[account_name]"
          onBlur={trackAccountName}
          onChange={handleAccountNameChange}
          value={accountName}
        />
      </FormFieldset>
      <FormFieldset>
        <TextField
          disabled={!active}
          error={!!errors.bsbAccountNumber}
          fullWidth
          helperText={errors.bsbAccountNumber}
          id="donation_bsb_account_number"
          inputComponent={StripeInput}
          inputProps={{
            component: AuBankAccountElement,
          }}
          label={i18n.t("donation.label.bsb_and_account_number")}
          onBlur={trackBsbAccountNumber}
          onChange={handleBsbAccountNumberChange}
        />
      </FormFieldset>
      <FormFieldset>
        <div>{i18n.t("donation.hint.becs_acceptance")}</div>
      </FormFieldset>
    </React.Fragment>
  )
}

BecsDirectDebitDetails.defaultProps = {
  accountName: "",
  active: false,
  bsbAccountNumber: false,
  errors: {},
  onChange: () => {},
}

BecsDirectDebitDetails.propTypes = {
  accountName: PropTypes.string,
  active: PropTypes.bool,
  // `bsbAccountNumber` is implicitly used in `trackBsbAccountNumber`
  // eslint-disable-next-line react/no-unused-prop-types
  bsbAccountNumber: PropTypes.bool,
  errors: errorsPropType,
  onChange: PropTypes.func,
}

export default React.memo(BecsDirectDebitDetails, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
)
