import StripeErrors from "lib/StripeErrors"
import StripeOnceOffFlow from "lib/StripeOnceOffFlow"
import StripeRecurringFlow from "lib/StripeRecurringFlow"
import StripePaymentIntentManager from "lib/StripePaymentIntentManager"

/**
 * Base class for Stripe payment processors.
 */
export default class StripeProcessor {
  /**
   * Create a `StripeProcessor`
   * @param stripe a reference to the Stripe JS SDK - https://stripe.com/docs/stripe-js
   * @param elements a reference to the Stripe Elements object in the Stripe JS SDK
   * @param donationForm a React ref to the donation form
   * @param tcDonateGateway an instance of `TcDonateGateway`, used to call the TC-Donate backend
   */
  constructor({ stripe, elements, donationForm, tcDonateGateway }) {
    this.stripe = stripe
    this.elements = elements
    this.donationForm = donationForm
    this.tcDonateGateway = tcDonateGateway

    this.stripePaymentIntentManager = new StripePaymentIntentManager({ tcDonateGateway })
  }

  savePaymentIntent(paymentMethod) {
    const { amount, frequency, recaptchaToken } = this.donationDetails
    const recurring = frequency !== "once"
    return this.stripePaymentIntentManager.save({
      amount,
      paymentMethod,
      recaptchaToken,
      recurring,
    })
  }

  /**
   * Submits the donation form to the back-end using `TcDonateGateway`, passing
   *   a merger of `this.donationDetails` and `paymentTokens`
   * @param paymentTokens Stripe tokens which the back-end needs to know about,
   *   either in order to complete payment, or to match to incoming webhooks
   */
  async submitForm({ donationDetails, paymentTokens }) {
    const donation = { paymentTokens, ...donationDetails }
    const result = await this.tcDonateGateway.call("donations.json", "POST", { donation })

    this.successUrl = result.success_url

    return result
  }

  /**
   * Internal interface to expose the appropriate current payment flow to
   * execute any payments on
   */
  get paymentFlow() {
    if (this.frequency === "monthly") {
      return new StripeRecurringFlow(this)
    }
    return new StripeOnceOffFlow(this)
  }

  /**
   * @return {string} the default field to add any errors to, e.g. "cardNumber"
   */
  // eslint-disable-next-line class-methods-use-this
  get defaultErrorField() {
    return "cardNumber"
  }

  /**
   * Internal interface to expose the current `donationDetails` on the `donationForm`
   * @return {{lastName: *, amount: *, title: *, frequency: *, name: *,
   *   recaptchaToken: *, paypal: boolean, email: *}}
   */
  get donationDetails() {
    return this.donationForm.current.donationDetails
  }

  /**
   * Executes the appropriate payment flow, requesting the creation of a
   * payment method, and processing payment using the current payment flow and
   * donation details. Returns the subscription UUID on success or throws an
   * exception on failure.
   */
  async executePayment() {
    const response = await this.createPaymentMethod()
    if (response.error) {
      throw StripeErrors(response, this.defaultErrorField)
    }
    const { donationDetails } = this
    const { paymentMethod } = response
    await this.paymentFlow.executePayment({ donationDetails, paymentMethod })

    return this.successUrl
  }

  /**
   * Called by the `StripeRecurringFlow` to process a new recurring payment in our back-end and wrap
   * the result so that it is in the form `StripeRecurringFlow` expects. In this case, we expect the
   * back-end to create a payment intent
   * @param donationDetails to be passed through to the backend when
   * submitting the donation. See `DonationFormManager.donationDetails`.
   * @param paymentTokens to be passed through to the back-end
   * @returns {Promise<{clientSecret: string, status: string}}
   */
  async executeRecurring({ donationDetails, paymentTokens }) {
    const result = await this.submitForm({ donationDetails, paymentTokens })
    const {
      stripe_payment_intent_client_secret: clientSecret,
      stripe_payment_intent_status: status,
    } = result
    return { clientSecret, status }
  }

  /**
   * Internal interface to expose the current frequency on the `donationForm`
   * @return {*} `"once"` or `"monthly"`
   */
  get frequency() {
    return this.donationForm.current.frequency
  }

  /**
   * Internal interface supplying a donor's available contact details to be
   *   passed to Stripe in as `billing_details` to `createPaymentMethod()` in
   *   the format Stripe expects
   * @return {{name: string, email: *}}
   * @see https://stripe.com/docs/js/payment_methods/create_payment_method
   * @see https://stripe.com/docs/js/deprecated/handle_card_payment
   */
  get billingDetails() {
    const { email, firstName, lastName } = this.donationDetails
    const name = [firstName, lastName].join(" ").trim()

    return { email, name }
  }
}
