/* eslint react/sort-comp: 0 */
import React from "react"
import { ElementsConsumer } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import { mapObject } from "underscore"

import DonationForm from "containers/DonationForm"
import CreditCardProcessor from "lib/CreditCardProcessor"
import DirectDebitProcessor from "lib/DirectDebitProcessor"
import NullDirectDebitProcessor from "lib/NullDirectDebitProcessor"
import BacsDirectDebitProcessor from "lib/BacsDirectDebitProcessor"
import BecsDirectDebitProcessor from "lib/BecsDirectDebitProcessor"
import SepaDirectDebitProcessor from "lib/SepaDirectDebitProcessor"
import WalletProcessor from "lib/WalletProcessor"
import doFetch from "lib/doFetch"
import i18n from "lib/i18n"
import Presets from "lib/Presets"
import PayPalManager from "lib/PayPalManager"
import TcDonateGateway from "lib/TcDonateGateway"
import { ConfigProps, ConfigDefaultProps } from "components/PropTypes/ConfigProps"
import { AmplitudeContext } from "contexts/AmplitudeContext"
import Validation from "./Validation"

const VALIDATIONS_DELAY = 100

const initialAmount = (presets, config, frequency) => {
  return presets.amount() || config.default_amounts_in_dollars[frequency]
}

const initialFrequency = (presets, config) => {
  const validFrequencies = config.frequency_allowed_values
  const validPresetFrequency = validFrequencies.find((e) => e === presets.frequency())

  return validPresetFrequency || config.default_frequency
}

// Choose a payment method from a set of available methods - credit card
// preferred, if available, then direct debit, then PayPal. Prefer
// presets.paymentMethod() if it is present, or just the first available
const initialPaymentMethod = (presets, creditCardProcessor, directDebitProcessor) => {
  const possiblePaymentMethods = ["paypal"]
  if (directDebitProcessor.available) {
    possiblePaymentMethods.unshift("direct_debit")
  }
  if (creditCardProcessor.available) {
    possiblePaymentMethods.unshift("credit_card")
  }

  let chosenPaymentMethod = possiblePaymentMethods.indexOf(presets.paymentMethod())
  if (chosenPaymentMethod < 0) {
    chosenPaymentMethod = 0
  }

  return possiblePaymentMethods[chosenPaymentMethod]
}

export class UnwrappedDonationFormManager extends React.Component {
  static contextType = AmplitudeContext // enables this.context

  constructor(props) {
    super(props)

    const frequency = initialFrequency(props.presets, props.config)
    const amount = initialAmount(props.presets, props.config, frequency)
    const paymentMethod = initialPaymentMethod(
      props.presets,
      props.creditCardProcessor,
      props.directDebitProcessor,
    )

    this.recaptchaRef = React.createRef()
    this.state = {
      accountName: "",
      address: "",
      amount,
      bsbAccountNumber: false,
      cardNumber: false,
      email: "",
      errors: {},
      expiry: false,
      firstName: "",
      frequency,
      giftAid: false,
      iban: false,
      lastName: "",
      paymentMethod,
      postcode: "",
      recaptchaToken: "",
      submitting: false,
      title: "",
      valid: false,
      // As we update, we run the validations, populating state.errors. We only
      // show the errors once we attempt payment (i.e. state.showErrors is true).
      // Once this happens, we'll be pushing down live error info to
      // DonationForm.
      showErrors: false,
      loading: false,
    }

    this.validation = new Validation({
      directDebitMethod: this.props.config.direct_debit_method,
      minAmount: this.props.config.amount_min,
      maxAmount: this.props.config.amount_max,
    })

    this.initiateProcessing = this.initiateProcessing.bind(this)
    this.initiateStripeProcessing = this.initiateStripeProcessing.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onPaypalClick = this.onPaypalClick.bind(this)
    this.onRecaptchaError = this.onRecaptchaError.bind(this)
    this.processStripePayment = this.processStripePayment.bind(this)
    this.loading = this.loading.bind(this)
    this.succeed = this.succeed.bind(this)
    this.setRecaptchaToken = this.setRecaptchaToken.bind(this)
    this.executePayment = this.executePayment.bind(this)
    this.validatePayment = this.validatePayment.bind(this)
    this.initialFrequency = frequency

    // Initialise errors
    this.state.validating = true
    this.validate(this.state)

    // Initialise pending validations (for batch processing)
    this.pendingValidations = {}
  }

  /**
   * Overridden React lifecycle method -
   * https://reactjs.org/docs/react-component.html#componentdidupdate
   *
   * Checks any change in state to see if we started or stopped submitting, and
   * disables or enables Stripe elements (credit card field, etc.) as appropriate
   * using the current processor's enableElements() method, if available
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      this.processor &&
      this.processor.enableElements &&
      prevState.submitting !== this.state.submitting
    ) {
      this.processor.enableElements(!this.state.submitting)
    }
  }

  setRecaptchaToken(recaptchaToken) {
    this.recaptchaRef.current.reset()
    this.setState({ recaptchaToken }, this.executePayment)
  }

  /**
   * Execute the payment on the payment processor and trigger the form to succeed or fail.
   * @return the new subscription's UUID
   */
  async executePayment() {
    if (this.processor) {
      try {
        const successUrl = await this.processor.executePayment()
        this.succeed(successUrl)
      } catch (error) {
        this.fail(error)
      }
    } else {
      this.fail(new Error(`Unknown paymentMethod - "${this.state.paymentMethod}"`))
    }
  }

  /**
   * Returns the current payment processor object given the current value of
   * `this.state.paymentMethod`
   * @return an instance of `StripeProcessor`, or `null` if `paymentMethod`
   * doesn't indicate an appropriate processor
   */
  get processor() {
    const { paymentMethod } = this.state
    if (paymentMethod === "credit_card") {
      return this.props.creditCardProcessor
    } else if (paymentMethod === "direct_debit") {
      return this.props.directDebitProcessor
    }
    return null
  }

  onRecaptchaError() {
    this.fail({ payment_error: i18n.t("recaptcha.error") })
  }

  /**
   * An external interface to allow payment managers to know the currently
   * selected payment frequency
   */
  get frequency() {
    return this.state.frequency
  }

  /**
   * Handles the `onSubmit` event of the main page form, which initiates
   * processing of a credit card payment (preceded by reCAPTCHA)
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault()

    // Clear queued validations as we're about to run our own
    if (this.state.validating) {
      clearTimeout(this.validationsTimeout)
    }

    const button = event.target.commit
    UnwrappedDonationFormManager.toggleSubmit(button, false)

    this.validatePayment(this.state.paymentMethod, () => {
      this.initiateStripeProcessing()
      UnwrappedDonationFormManager.toggleSubmit(button, true)
    })
  }

  /**
   * Calls `initiateProcessing()` with `processStripePayment()` as a
   * callback. Separated as its own method to facilitate unit testing.
   */
  initiateStripeProcessing() {
    this.initiateProcessing(this.processStripePayment)
  }

  /**
   * Called when responding to a click intended to trigger payment processing.
   * If there are any validation errors, it makes them visible on the form.
   * If not, it renders the form inactive and runs `processPayment`.
   *
   * @param processPayment a callback to be run if the form is valid
   */
  initiateProcessing(processPayment) {
    this.setState({ showErrors: true, submitting: this.state.valid }, () => {
      if (processPayment && this.state.valid) {
        processPayment()
      }
    })
  }

  /**
   * Called when a payment method has initiated processing and then cancelled,
   * so that the form can be rendered active again
   */
  onCancel() {
    this.setState({ submitting: false })
  }

  /**
   * Starts processing credit card payment, which is actually triggered by the
   * successful completion of the reCAPTCHA process
   */
  processStripePayment() {
    this.recaptchaRef.current.execute()
  }

  /**
   * The region code passed in as config
   * @return {*}
   */
  get regionCode() {
    return this.props.config.region_code
  }

  /**
   * Setting `state.submitting` to true in `handleSubmit` will result in the
   * form's submit button being disabled, but maybe not in time to prevent the
   * donor double-clicking it. This would cause attempted double submission of
   * the same payment intent (_not double payment_), causing an ephemeral error
   * message in the UI and a Bugsnag error. Hence, we use standard DOM
   * manipulation here to ensure the button is disabled right away, making it
   * impossible to double-click. Then, we call `toggleSubmit` again to reactivate
   * the button.
   * @see https://app.bugsnag.com/the-conversation/tc-donations/errors/60176941205f860018a4f947
   */
  static toggleSubmit(button, enabled) {
    // eslint-disable-next-line no-param-reassign
    button.disabled = !enabled
  }

  /**
   * Visually indicate that the page is loading. Used to encourage donors to
   * stay on the page after client-side payment is complete while they wait
   * for the donation to be submitted to the server.
   */
  loading() {
    this.setState({ loading: true })
  }

  /**
   * Cancel the loading indicator.
   */
  cancelLoading() {
    this.setState({ loading: false })
  }

  /**
   * Called by payment managers when their processing has completed successfully
   *   - navigates to the success page
   * @param url a URL to navigate to
   */
  // eslint-disable-next-line class-methods-use-this
  succeed(url) {
    window.location = url
  }

  /**
   * Sets the state to the given payment provider, validates the form given this
   * provider, then runs initiatePayment
   * @param {string} paymentMethod - One of "credit_card", "paypal" and "wallet"
   * @param initiatePayment
   */
  validatePayment(paymentMethod, initiatePayment) {
    this.setState({ paymentMethod }, () => this.validate({}, initiatePayment))
  }

  /**
   * Called by payment managers when their processing has failed. Updates form
   *   error state and sets `state.submitting` and `state.loading` to `false`
   * @param newErrors relevant error info from the failure, for processing by
   *   `unpackErrors()`
   * @return {boolean}
   */
  fail(newErrors) {
    const newErrorsOrDefault = this.unpackErrors(newErrors)
    this.setState((prevState) => {
      const errors = { ...prevState.errors, ...newErrorsOrDefault }
      return { errors, loading: false, submitting: false }
    })
    return false
  }

  handleChange(newState) {
    this.setState({ ...newState, validating: true })
    this.enqueueValidation(newState)
  }

  /**
   * Debounce validations by collecting all changes into
   * this.pendingValidations, then deferring them.
   */
  enqueueValidation(newState) {
    if (this.validationsTimeout) {
      clearTimeout(this.validationsTimeout)
    }

    this.pendingValidations = { ...this.pendingValidations, ...newState }

    this.validationsTimeout = setTimeout(() => {
      this.validate(this.pendingValidations)
      this.pendingValidations = {}
    }, VALIDATIONS_DELAY)
  }

  /**
   * Runs the current validation against a combination of `newState` and the
   * existing state, and sets state accordingly
   * @param newState
   * @param callback a method to run once validation has occurred
   */
  validate(newState, callback) {
    const state = { ...this.state, ...newState }

    this.validation
      .validate(state)
      .then(() => {
        this.setState(
          {
            errors: {},
            valid: true,
            validating: false,
          },
          callback,
        )
      })
      .catch((errors) => {
        this.setState(
          {
            errors,
            valid: false,
            validating: false,
          },
          callback,
        )
      })
  }

  /**
   * Takes `result` and returns something based on it which can be used to set
   *   error fields on the form. If `result`:
   * 1. has an `errors` property, returns the value of that property, or the
   *   first member if the value is an array, e.g.
   *   `{"errors": {"cardNumber": "is invalid"}}` => `{"cardNumber": "is invalid"}`
   *   `{"errors": {"cardNumber": ["is invalid", "is fraudulent"]}}` => `{"cardNumber": "is invalid"}`
   * 2. has no `errors property, but has other properties, it returns `result`
   *   unchanged, e.g. `{"cardNumber": "is invalid"}` => `{"cardNumber": "is invalid"}`
   * 3. is falsey, returns a default error on the appropriate field
   *   given the current payment method, e.g. `null` => `{"paypal": "Default error"}`
   * 4. is an Error, returns a default error as with a falsey value above
   * @param result an error result passed back from a payment processor
   * @return {*} a set of field names and their error messages
   */
  unpackErrors(result) {
    if (!result || result instanceof Error) {
      const { paymentMethod } = this.state
      let errorField = "paypal"
      if (paymentMethod === "credit_card") {
        errorField = "cardNumber"
      } else if (paymentMethod === "wallet") {
        errorField = "payment_error"
      }
      return { [errorField]: i18n.t("donation.message.payment_error") }
    }
    if (result.errors) {
      return mapObject(result.errors, (message) => {
        if (Array.isArray(message)) {
          return message[0]
        }
        return message
      })
    }
    return result
  }

  /**
   * The set of form values which the back-end and/or Stripe needs to know about,
   *   representing a completed donation
   * @return {{lastName: *, amount: *, title: *, frequency: *, firstName: *,
   *   recaptchaToken: *, paymentMethod: string, email: *}}
   */
  get donationDetails() {
    const {
      accountName,
      address,
      amount,
      city,
      country,
      email,
      frequency,
      giftAid,
      firstName,
      lastName,
      paymentMethod,
      postcode,
      recaptchaToken,
      state,
      title,
    } = this.state

    // For maximum safety, handle cases where context is not initialised,
    // or amplitude client is not initialised.
    const amplitudeDeviceId = this.context?.client?.getDeviceId() || ""

    return {
      accountName,
      address,
      amount,
      amplitudeDeviceId,
      city,
      country,
      email,
      firstName,
      frequency,
      giftAid,
      lastName,
      paymentMethod,
      postcode,
      recaptchaToken,
      state,
      title,
    }
  }

  /**
   * A public onClick callback for a `PayPalManager` to hook up to a PayPal
   *   button. Validates and initiates form processing. PayPal itself takes
   *   care of showing the payment window.
   */
  onPaypalClick() {
    this.validatePayment("paypal", this.initiateProcessing)
  }

  render() {
    const errors = this.state.showErrors ? this.state.errors : {}
    return (
      <DonationForm
        accountName={this.state.accountName}
        address={this.state.address}
        amount={this.state.amount}
        bsbAccountNumber={this.state.bsbAccountNumber}
        cardNumber={this.state.cardNumber}
        city={this.state.city}
        config={{
          currencySign: this.props.config.region_currency_sign,
          defaultAmounts: this.props.config.default_amounts_in_dollars,
          initialFrequency: this.initialFrequency,
          locale: this.props.config.locale,
          showGiftAid: this.props.config.gift_aid_on_front_page,
          suggestedAmounts: this.props.config.suggested_amounts_in_dollars,
        }}
        country={this.state.country}
        cvv={this.state.cvv}
        creditCardProcessor={this.props.creditCardProcessor}
        directDebitProcessor={this.props.directDebitProcessor}
        walletProcessor={this.props.walletProcessor}
        email={this.state.email}
        errors={errors}
        expiry={this.state.expiry}
        fieldsActive={!this.state.submitting}
        frequency={this.state.frequency}
        giftAid={this.state.giftAid}
        iban={this.state.iban}
        lastName={this.state.lastName}
        loading={this.state.loading}
        firstName={this.state.firstName}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        payPalManager={this.props.payPalManager}
        paymentMethod={this.state.paymentMethod}
        postcode={this.state.postcode}
        recaptcha={{
          enabled: this.props.config.recaptcha_enabled,
          instance: this.recaptchaRef,
          onError: this.onRecaptchaError,
          onVerify: this.setRecaptchaToken,
          siteKey: this.props.config.recaptcha_siteKey,
        }}
        state={this.state.state}
        submitActive={!this.state.submitting}
        title={this.state.title}
        url={this.props.url}
        valid={this.state.valid}
      />
    )
  }
}

const DonationFormManager = ({ config, presets, tcDonateGateway, url }) => (
  <ElementsConsumer>
    {({ elements, stripe }) => {
      const donationForm = React.createRef()
      const payPalManager = new PayPalManager({
        allowed: config.Paypal_allowed,
        donationForm,
        env: config.Paypal_env,
        tcDonateGateway,
      })
      const creditCardProcessor = new CreditCardProcessor({
        stripe,
        elements,
        donationForm,
        tcDonateGateway,
        allowed: !!config.Stripe_PublishableKey,
      })
      let directDebitProcessor = new NullDirectDebitProcessor({})
      if (config.direct_debit_method === "Bacs") {
        directDebitProcessor = new BacsDirectDebitProcessor({
          donationForm,
          elements,
          stripe,
          tcDonateGateway,
        })
      } else if (config.direct_debit_method === "BECS") {
        directDebitProcessor = new BecsDirectDebitProcessor({
          donationForm,
          elements,
          stripe,
          tcDonateGateway,
        })
      } else if (config.direct_debit_method === "SEPA") {
        directDebitProcessor = new SepaDirectDebitProcessor({
          donationForm,
          elements,
          stripe,
          tcDonateGateway,
        })
      }
      const walletProcessor = new WalletProcessor({
        stripe,
        donationForm,
        tcDonateGateway,
        country: config.region_home_country_code,
        currency: config.region_currency_code,
        label: i18n.t("donation.label.wallet_payment_description"),
      })
      return (
        <UnwrappedDonationFormManager
          config={config}
          creditCardProcessor={creditCardProcessor}
          directDebitProcessor={directDebitProcessor}
          walletProcessor={walletProcessor}
          elements={elements}
          payPalManager={payPalManager}
          presets={presets}
          ref={donationForm}
          tcDonateGateway={tcDonateGateway}
          url={url}
        />
      )
    }}
  </ElementsConsumer>
)

const sharedDefaultProps = {
  config: ConfigDefaultProps,
  presets: new Presets(),
  tcDonateGateway: new TcDonateGateway({
    authenticityToken: "proper-auth-token",
    basePath: "/au",
    doFetch,
    region: "au",
  }),
  url: "/donations",
}

DonationFormManager.defaultProps = {
  ...sharedDefaultProps,
}

UnwrappedDonationFormManager.defaultProps = {
  creditCardProcessor: new CreditCardProcessor({}),
  directDebitProcessor: new NullDirectDebitProcessor({}),
  walletProcessor: new WalletProcessor({}),
  payPalManager: new PayPalManager({}),
  ...sharedDefaultProps,
}

const sharedPropTypes = {
  config: ConfigProps,
  presets: PropTypes.instanceOf(Presets),
  tcDonateGateway: PropTypes.instanceOf(TcDonateGateway),
  url: PropTypes.string,
}

DonationFormManager.propTypes = {
  ...sharedPropTypes,
}

UnwrappedDonationFormManager.propTypes = {
  creditCardProcessor: PropTypes.instanceOf(CreditCardProcessor),
  directDebitProcessor: PropTypes.instanceOf(DirectDebitProcessor),
  walletProcessor: PropTypes.instanceOf(WalletProcessor),
  payPalManager: PropTypes.instanceOf(PayPalManager),
  ...sharedPropTypes,
}

export default DonationFormManager
