import StripeErrors from "lib/StripeErrors"

export default class StripeRecurringFlow {
  constructor(processor) {
    this.processor = processor
  }

  /**
   * 1. Submits the donor's contact details and the payment intent to our
   *   backend, which which creates a new customer and subscription in Stripe
   *   (`submitForm()`) and returns an object containing some details of the
   *   payment intent and its status
   * 2. Calls out to Stripe for further confirmation if the payment intent's
   *   status requires it (`confirmRecurring()`)
   * @param paymentMethod a payment method provided by Stripe, e.g. as returned
   *   from payment processor `createPaymentMethod()`
   * @param donationDetails to be passed through to the TC-Donate backend when
   * submitting the donation. See `DonationFormManager.donationDetails`.
   * @return {Promise<unknown>}
   */
  async executePayment({ donationDetails, paymentMethod }) {
    const stripePaymentMethodId = paymentMethod.id
    const paymentTokens = { stripePaymentMethodId }
    const result = await this.processor.executeRecurring({
      donationDetails,
      paymentTokens,
    })

    // Call out to the user for confirmation using confirmRecurring if
    // returned status is "requires_action". If status is "requires_source",
    // then there is an error
    // see https://stripe.com/docs/payments/accept-a-payment-synchronously#web-create-payment-intent
    const { clientSecret, status } = result
    if (status) {
      if (status.match(/requires_source$/)) {
        throw StripeErrors()
      }
      // https://stripe.com/docs/payments/intents#intent-statuses
      if (status.match(/requires_(confirmation|action|source_action)/)) {
        const setupResult = await this.processor.confirmRecurring({ clientSecret })

        if (setupResult.error) {
          const error = { cardNumber: setupResult.error.message }
          throw error
        }
      }
    }
  }
}
