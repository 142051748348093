import i18n from "lib/i18n"

// Package error response from Stripe into a message on the
// appropriate field of DonationForm
// @param defaultField default field to add the error to
const StripeErrors = (response, defaultField = "cardNumber") => {
  const { code } = response.error
  const message = response.error.message || i18n.t("donation.message.payment_error")
  if (code) {
    if (code.match("expiry")) {
      return { expiry: message }
    } else if (code.match("cvc")) {
      return { cvv: message }
    } else if (code.match("email")) {
      return { email: message }
    }
  }
  return { [defaultField]: message }
}

export default StripeErrors
