import React from "react"
import PropTypes from "prop-types"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@theconversation/ui"

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
})

const Loading = ({ show, classes }) => (
  <Backdrop className={classes.backdrop} open={show}>
    <CircularProgress color="inherit" />
  </Backdrop>
)

Loading.defaultProps = {
  classes: {
    backdrop: "",
  },
  show: false,
}

Loading.propTypes = {
  classes: PropTypes.shape({
    backdrop: PropTypes.string,
  }),
  show: PropTypes.bool,
}

export default withStyles(styles)(Loading)
