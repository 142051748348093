const fr = {
  // https://raw.githubusercontent.com/umpirsky/country-list/master/data/fr/country.json
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BY: "Biélorussie",
  BO: "Bolivie",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BR: "Brésil",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  EA: "Ceuta et Melilla",
  CL: "Chili",
  CN: "Chine",
  CY: "Chypre",
  CO: "Colombie",
  KM: "Comores",
  CG: "Congo-Brazzaville",
  CD: "Congo-Kinshasa",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  CR: "Costa Rica",
  CI: "Côte d’Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  DK: "Danemark",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DM: "Dominique",
  EG: "Égypte",
  SV: "El Salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  VA: "État de la Cité du Vatican",
  FM: "États fédérés de Micronésie",
  US: "États-Unis",
  ET: "Éthiopie",
  EZ: "Eurozone",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du Sud et îles Sandwich du Sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GQ: "Guinée équatoriale",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  GF: "Guyane française",
  HT: "Haïti",
  HN: "Honduras",
  HU: "Hongrie",
  CX: "Île Christmas",
  AC: "Île de l’Ascension",
  IM: "Île de Man",
  NF: "Île Norfolk",
  AX: "Îles Åland",
  KY: "Îles Caïmans",
  IC: "Îles Canaries",
  CC: "Îles Cocos",
  CK: "Îles Cook",
  FO: "Îles Féroé",
  FK: "Îles Malouines",
  MP: "Îles Mariannes du Nord",
  MH: "Îles Marshall",
  UM: "Îles mineures éloignées des États-Unis",
  PN: "Îles Pitcairn",
  SB: "Îles Salomon",
  TC: "Îles Turques-et-Caïques",
  VG: "Îles Vierges britanniques",
  VI: "Îles Vierges des États-Unis",
  IN: "Inde",
  ID: "Indonésie",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  XK: "Kosovo",
  KW: "Koweït",
  RE: "La Réunion",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MK: "Macédoine",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MA: "Maroc",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar (Birmanie)",
  NA: "Namibie",
  UN: "Nations Unies",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  NL: "Pays-Bas",
  BQ: "Pays-Bas caribéens",
  PE: "Pérou",
  PH: "Philippines",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto Rico",
  PT: "Portugal",
  QA: "Qatar",
  HK: "R.A.S. chinoise de Hong Kong",
  MO: "R.A.S. chinoise de Macao",
  CF: "République centrafricaine",
  DO: "République dominicaine",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  EH: "Sahara occidental",
  BL: "Saint-Barthélemy",
  KN: "Saint-Christophe-et-Niévès",
  SM: "Saint-Marin",
  MF: "Saint-Martin",
  SX: "Saint-Martin (partie néerlandaise)",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les-Grenadines",
  SH: "Sainte-Hélène",
  LC: "Sainte-Lucie",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  SS: "Soudan du Sud",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et Jan Mayen",
  SZ: "Swaziland",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TD: "Tchad",
  CZ: "Tchéquie",
  TF: "Terres australes françaises",
  IO: "Territoire britannique de l’océan Indien",
  PS: "Territoires palestiniens",
  TH: "Thaïlande",
  TL: "Timor oriental",
  TG: "Togo",
  TK: "Tokélaou",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TA: "Tristan da Cunha",
  TN: "Tunisie",
  TM: "Turkménistan",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis-et-Futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
}

export default fr
