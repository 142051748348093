import ErrorTrack from "lib/ErrorTrack"
import StripeErrors from "lib/StripeErrors"
import { get } from "underscore"

export default class StripeOnceOffFlow {
  constructor(processor) {
    this.processor = processor
  }

  /**
   * Executes a once-off Stripe payment, submitting the relevant details to the
   * backend using `submitForm()` and then completing payment using
   * `confirmOnceOff()`
   * @param paymentMethod a payment method provided by Stripe, e.g. as returned
   *   from payment processor `createPaymentMethod()`
   * @param donationDetails to be passed through to the TC-Donate backend when
   * submitting the donation. See `DonationFormManager.donationDetails`.
   * @return {Promise<unknown>}
   */
  async executePayment({ donationDetails, paymentMethod }) {
    ErrorTrack.leaveBreadcrumb("StripeOnceOffFlow.executePayment()", { paymentMethod })
    // If paymentMethod.id is missing, avoid making a payment intent call to our
    // server which will only fail by throwing. This will cause a generic error
    // to appear in the UI
    // (see https://app.bugsnag.com/the-conversation/tc-donations/errors/607bbd675c90fc000862c4a4)
    if (!paymentMethod.id) {
      throw new Error("Blank payment method ID")
    }
    const {
      id: stripePaymentIntentId,
      client_secret: clientSecret,
      status: stripePaymentIntentStatus,
    } = await this.processor.savePaymentIntent(paymentMethod.id)
    const stripePaymentMethodType = paymentMethod.type
    const stripePaymentMethodWalletType = get(paymentMethod, ["card", "wallet", "type"])
    const paymentData = {
      stripePaymentIntentId,
      stripePaymentIntentStatus,
      stripePaymentMethodType,
      stripePaymentMethodWalletType,
    }
    await this.processor.submitForm({
      donationDetails,
      paymentTokens: { paymentData },
    })
    const cardPaymentResponse = await this.processor.confirmOnceOff({ clientSecret })
    if (cardPaymentResponse.error) {
      throw StripeErrors(cardPaymentResponse)
    }
  }
}
