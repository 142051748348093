const es = {
  // https://raw.githubusercontent.com/umpirsky/country-list/master/data/es/country.json
  AF: "Afganist\u00e1n",
  AL: "Albania",
  DE: "Alemania",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguila",
  AQ: "Ant\u00e1rtida",
  AG: "Antigua y Barbuda",
  SA: "Arabia Saud\u00ed",
  DZ: "Argelia",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaiy\u00e1n",
  BS: "Bahamas",
  BD: "Banglad\u00e9s",
  BB: "Barbados",
  BH: "Bar\u00e9in",
  BE: "B\u00e9lgica",
  BZ: "Belice",
  BJ: "Ben\u00edn",
  BM: "Bermudas",
  BY: "Bielorrusia",
  BO: "Bolivia",
  BA: "Bosnia y Herzegovina",
  BW: "Botsuana",
  BR: "Brasil",
  BN: "Brun\u00e9i",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  BT: "But\u00e1n",
  CV: "Cabo Verde",
  KH: "Camboya",
  CM: "Camer\u00fan",
  CA: "Canad\u00e1",
  BQ: "Caribe neerland\u00e9s",
  QA: "Catar",
  TD: "Chad",
  CZ: "Chequia",
  CL: "Chile",
  CN: "China",
  CY: "Chipre",
  VA: "Ciudad del Vaticano",
  CO: "Colombia",
  KM: "Comoras",
  CG: "Congo",
  KP: "Corea del Norte",
  KR: "Corea del Sur",
  CR: "Costa Rica",
  CI: "C\u00f4te d\u2019Ivoire",
  HR: "Croacia",
  CU: "Cuba",
  CW: "Curazao",
  DK: "Dinamarca",
  DM: "Dominica",
  EC: "Ecuador",
  EG: "Egipto",
  SV: "El Salvador",
  AE: "Emiratos \u00c1rabes Unidos",
  ER: "Eritrea",
  SK: "Eslovaquia",
  SI: "Eslovenia",
  ES: "Espa\u00f1a",
  US: "Estados Unidos",
  EE: "Estonia",
  SZ: "Esuatini",
  ET: "Etiop\u00eda",
  PH: "Filipinas",
  FI: "Finlandia",
  FJ: "Fiyi",
  FR: "Francia",
  GA: "Gab\u00f3n",
  GM: "Gambia",
  GE: "Georgia",
  GH: "Ghana",
  GI: "Gibraltar",
  GD: "Granada",
  GR: "Grecia",
  GL: "Groenlandia",
  GP: "Guadalupe",
  GU: "Guam",
  GT: "Guatemala",
  GF: "Guayana Francesa",
  GG: "Guernsey",
  GN: "Guinea",
  GQ: "Guinea Ecuatorial",
  GW: "Guinea-Bis\u00e1u",
  GY: "Guyana",
  HT: "Hait\u00ed",
  HN: "Honduras",
  HU: "Hungr\u00eda",
  IN: "India",
  ID: "Indonesia",
  IQ: "Irak",
  IR: "Ir\u00e1n",
  IE: "Irlanda",
  BV: "Isla Bouvet",
  IM: "Isla de Man",
  CX: "Isla de Navidad",
  NF: "Isla Norfolk",
  IS: "Islandia",
  AX: "Islas \u00c5land",
  KY: "Islas Caim\u00e1n",
  CC: "Islas Cocos",
  CK: "Islas Cook",
  FO: "Islas Feroe",
  GS: "Islas Georgia del Sur y Sandwich del Sur",
  HM: "Islas Heard y McDonald",
  FK: "Islas Malvinas",
  MP: "Islas Marianas del Norte",
  MH: "Islas Marshall",
  UM: "Islas menores alejadas de EE. UU.",
  PN: "Islas Pitcairn",
  SB: "Islas Salom\u00f3n",
  TC: "Islas Turcas y Caicos",
  VG: "Islas V\u00edrgenes Brit\u00e1nicas",
  VI: "Islas V\u00edrgenes de EE. UU.",
  IL: "Israel",
  IT: "Italia",
  JM: "Jamaica",
  JP: "Jap\u00f3n",
  JE: "Jersey",
  JO: "Jordania",
  KZ: "Kazajist\u00e1n",
  KE: "Kenia",
  KG: "Kirguist\u00e1n",
  KI: "Kiribati",
  KW: "Kuwait",
  LA: "Laos",
  LS: "Lesoto",
  LV: "Letonia",
  LB: "L\u00edbano",
  LR: "Liberia",
  LY: "Libia",
  LI: "Liechtenstein",
  LT: "Lituania",
  LU: "Luxemburgo",
  MK: "Macedonia del Norte",
  MG: "Madagascar",
  MY: "Malasia",
  MW: "Malaui",
  MV: "Maldivas",
  ML: "Mali",
  MT: "Malta",
  MA: "Marruecos",
  MQ: "Martinica",
  MU: "Mauricio",
  MR: "Mauritania",
  YT: "Mayotte",
  MX: "M\u00e9xico",
  FM: "Micronesia",
  MD: "Moldavia",
  MC: "M\u00f3naco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar (Birmania)",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NI: "Nicaragua",
  NE: "N\u00edger",
  NG: "Nigeria",
  NU: "Niue",
  NO: "Noruega",
  NC: "Nueva Caledonia",
  NZ: "Nueva Zelanda",
  OM: "Om\u00e1n",
  NL: "Pa\u00edses Bajos",
  PK: "Pakist\u00e1n",
  PW: "Palaos",
  PA: "Panam\u00e1",
  PG: "Pap\u00faa Nueva Guinea",
  PY: "Paraguay",
  PE: "Per\u00fa",
  PF: "Polinesia Francesa",
  PL: "Polonia",
  PT: "Portugal",
  PR: "Puerto Rico",
  HK: "RAE de Hong Kong (China)",
  MO: "RAE de Macao (China)",
  GB: "Reino Unido",
  CF: "Rep\u00fablica Centroafricana",
  CD: "Rep\u00fablica Democr\u00e1tica del Congo",
  DO: "Rep\u00fablica Dominicana",
  RE: "Reuni\u00f3n",
  RW: "Ruanda",
  RO: "Ruman\u00eda",
  RU: "Rusia",
  EH: "S\u00e1hara Occidental",
  WS: "Samoa",
  AS: "Samoa Americana",
  BL: "San Bartolom\u00e9",
  KN: "San Crist\u00f3bal y Nieves",
  SM: "San Marino",
  MF: "San Mart\u00edn",
  PM: "San Pedro y Miquel\u00f3n",
  VC: "San Vicente y las Granadinas",
  SH: "Santa Elena",
  LC: "Santa Luc\u00eda",
  ST: "Santo Tom\u00e9 y Pr\u00edncipe",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leona",
  SG: "Singapur",
  SX: "Sint Maarten",
  SY: "Siria",
  SO: "Somalia",
  LK: "Sri Lanka",
  ZA: "Sud\u00e1frica",
  SD: "Sud\u00e1n",
  SS: "Sud\u00e1n del Sur",
  SE: "Suecia",
  CH: "Suiza",
  SR: "Surinam",
  SJ: "Svalbard y Jan Mayen",
  TH: "Tailandia",
  TW: "Taiw\u00e1n",
  TZ: "Tanzania",
  TJ: "Tayikist\u00e1n",
  IO: "Territorio Brit\u00e1nico del Oc\u00e9ano \u00cdndico",
  TF: "Territorios Australes Franceses",
  PS: "Territorios Palestinos",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad y Tobago",
  TN: "T\u00fanez",
  TM: "Turkmenist\u00e1n",
  TR: "Turqu\u00eda",
  TV: "Tuvalu",
  UA: "Ucrania",
  UG: "Uganda",
  UY: "Uruguay",
  UZ: "Uzbekist\u00e1n",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  WF: "Wallis y Futuna",
  YE: "Yemen",
  DJ: "Yibuti",
  ZM: "Zambia",
  ZW: "Zimbabue",
}

export default es
