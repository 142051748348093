/* eslint class-methods-use-this: ["error", { "exceptMethods": ["reset", "render"] }] */
import PropTypes from "prop-types"
import React from "react"

export default class NullRecaptcha extends React.Component {
  reset() {}

  execute() {
    const { onVerify } = this.props
    return onVerify()
  }

  render() {
    return null
  }
}

NullRecaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
}
