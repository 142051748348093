import React, { useCallback } from "react"
import PropTypes from "prop-types"
import Analytics from "lib/Analytics"
import i18n from "lib/i18n"
import FormFieldset from "components/FormFieldset"
import { TextField } from "@theconversation/ui"
import errorsPropType from "components/PropTypes/errorsPropType"

const trackChange = (event) => {
  const value = event.target.value
  if (value.length) {
    Analytics.trackFormEvent("Donation Form", "Completed", "Tax ID")
  } else {
    Analytics.trackFormEvent("Donation Form", "Skipped", "Tax ID")
  }
}

// Shows a Tax ID field if the user is in Spain
const TaxId = ({ active, errors, onChange, taxId, formName }) => {
  // Change handling
  const handleChange = useCallback((event) => onChange({ taxId: event.target.value }), [onChange])

  return (
    <FormFieldset>
      <TextField
        autoComplete
        disabled={!active}
        error={!!errors.taxId}
        fullWidth
        label={i18n.t("donation.label.tax_id")}
        helperText={errors.taxId}
        id={`${formName}_tax_id`}
        name={`${formName}[tax_id]`}
        onBlur={trackChange}
        onChange={handleChange}
        type="text"
        value={taxId}
      />
    </FormFieldset>
  )
}

export default React.memo(TaxId)

TaxId.defaultProps = {
  active: false,
  errors: {},
  onChange: () => {},
  taxId: "",
  formName: "subscription",
}

TaxId.propTypes = {
  active: PropTypes.bool,
  errors: errorsPropType,
  onChange: PropTypes.func,
  taxId: PropTypes.string,
  formName: PropTypes.string,
}
