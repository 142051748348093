import Bugsnag from "@bugsnag/js"
import { get } from "underscore"

// A wrapper around bugsnag.
//
// If you want to track errors on a particular page, you should
// import this module and call ErrorTrack.start() during the page
// object initialization.
//
// Calling ErrorTrack.start() will automatically capture a lot of
// interesting stuff.
//
// See https://docs.bugsnag.com/platforms/browsers/js/
//
export default class ErrorTrack {
  static start(config) {
    try {
      Bugsnag.start({ onError: ErrorTrack.onError, ...config })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Bugsnag failed to start. Bad configuration?")
    }
  }

  /**
   * Wraps a call to leave a breadcrumb in Bugsnag to help debugging an error
   * which may occur later
   * @param message passed to Bugsnag.leaveBreadcrumb()
   * @param metadata passed to Bugsnag.leaveBreadcrumb()
   * @see https://docs.bugsnag.com/platforms/javascript/customizing-breadcrumbs/#adding-manual-breadcrumbs
   */
  static leaveBreadcrumb(message, metadata) {
    if (Bugsnag) {
      Bugsnag.leaveBreadcrumb(message, metadata)
    } else {
      // eslint-disable-next-line no-console
      console.warn("Bugsnag client not initialized! Call ErrorTrack.start() first.")
    }
  }

  // Wraps a call to pass `exception` to Bugsnag
  //
  // `severity` overrides the default severity level. It can be one of "error",
  // "warning" or "info". Default is "warning" for errors passed to `Bugsnag.notify`.
  // See https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#severity
  static notify(exception, severity) {
    if (Bugsnag) {
      Bugsnag.notify(exception, ErrorTrack.notifyCallback({ severity }))
    } else {
      // eslint-disable-next-line no-console
      console.warn("Bugsnag client not initialized! Call ErrorTrack.start() first.")
    }
  }

  // Returns a callback we can pass in to `notify` so as to customize the error
  // report.
  // See https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#the-event-object
  static notifyCallback({ severity }) {
    return (event) => {
      if (severity) {
        // eslint-disable-next-line no-param-reassign
        event.severity = severity
      }
    }
  }

  // Add any logic here for updating an error event before passing it to Bugsnag.
  // See
  // https://docs.bugsnag.com/platforms/javascript/restify/customizing-error-reports/#updating-events-using-callbacks
  static onError(event) {
    // Open up the Bugsnag Event object.
    // See https://docs.bugsnag.com/platforms/javascript/restify/customizing-error-reports/#the-event-object
    // n.b. we're using underscore's `get` method to provide safe navigation
    // - https://underscorejs.org/#get
    const error = get(event, ["errors", 0])
    const file = get(error, ["stacktrace", 0, "file"])
    if (
      /^NS_ERROR_FILE_CORRUPTED/.test(error.errorMessage) &&
      /https:\/\/www\.paypal\.com/.test(file)
    ) {
      // PayPal's SDK can encounter this error when it uses FireFox's local
      // storage. It's a browser error we can't fix, so we should ignore it.
      // See https://stackoverflow.com/questions/18877643/error-in-local-storage-ns-error-file-corrupted-firefox
      return false
    }
    const payPalLogError =
      /(Can not send postrobot_method|Window closed for postrobot_method before ack)/
    if (payPalLogError.test(error.errorMessage)) {
      // These messages indicate that the PayPal SDK couldn't send internal logs,
      // likely because the PayPal button was removed from the page. This is
      // unfortunate, but we need to remove the PayPal button sometimes, e.g.
      // when switching between once off and monthly payments, and there don't
      // seem to be any negative consequences for our users, so we'll ignore it.
      // See https://github.com/paypal/paypal-checkout-components/issues/404
      return false
    }
    if (error.errorMessage.startsWith("unhandledrejection handler received a non-error")) {
      if (event.originalError.startsWith("Object Not Found Matching Id")) {
        // We've occasionally received a crap ton of unhandled rejections of this
        // form in quick succession such that Bugsnag has ended up sampling our
        // errors. They seem to come from Embedded Chromium on Windows. They are
        // not a thing we can fix, and it's dangerous not to reject them because
        // they can result in sampling.
        // See https://github.com/cefsharp/CefSharp/blob/master/CefSharp/Internals/JavascriptObjectRepository.cs#L293
        return false
      }
    }
    const ignorablePatterns =
      // 1. `moz-extension` protocol - the error originates from a Firefox extension
      //   and it's out of our control to fix
      // 2. `user-script` protocol, the error comes from a Safari user script and
      //   it's out of our control to fix
      // 3. the website Hypothesis, which uses extensions to mark up
      //   existing pages - https://web.hypothes.is/about/
      // 4. file:// protocol, indicates that the site is being run from a local
      // file system. Probably this means a user has saved the page and is
      //  attempting to open the saved page, which will break payments JS, etc.
      // 5. `global code` - the error is in the global code space, almost
      //  certainly added there by a script we don't control or by the browser
      //  itself
      /^(moz-extension|user-script|https:\/\/via\.hypothes\.is|file:\/|global code|pptr)/

    // If the stacktrace on the originating error is from a file which matches
    // certain criteria, ignore the event by returning false
    if (ignorablePatterns.test(file)) {
      return false
    }
    // Sometimes Bugsnag doesn't report a file, but it should report a URL.
    if (ignorablePatterns.test(event.request.url)) {
      return false
    }
    return true
  }
}
