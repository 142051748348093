// Enables a React component to be told the current width of the window.
// `setWindowWidth` is a React state callback. Use it like so:
//
// const MyWidthSensitiveComponent = () => {
//    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
//    useEffect(watchWindowWidth(setWindowWidth), []);
//    // render something whose behaviour changes depending on `windowWidth`
const watchWindowWidth = (setWindowWidth) => {
  const handleWindowResize = () => setWindowWidth(window.innerWidth)
  window.addEventListener("resize", handleWindowResize)
  return () => window.removeEventListener("resize", handleWindowResize)
}

export default watchWindowWidth
