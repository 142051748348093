import React, { useEffect, useState } from "react"

import { ThemeProvider } from "@theconversation/ui"
import AddressDetails from "components/AddressDetails"
import AmountDetails from "components/AmountDetails"
import Analytics from "lib/Analytics"
import ContactDetails from "components/ContactDetails"
import CreditCardProcessor from "lib/CreditCardProcessor"
import DirectDebitProcessor from "lib/DirectDebitProcessor"
import donationsTheme from "components/donationsTheme"
import errorsPropType from "components/PropTypes/errorsPropType"
import FormError from "components/FormError"
import GiftAidCheckbox from "components/GiftAidCheckbox"
import Loading from "components/Loading"
import NullDirectDebitProcessor from "lib/NullDirectDebitProcessor"
import PaymentDetails from "components/PaymentDetails"
import paymentMethodPropType from "components/PropTypes/paymentMethodPropType"
import PaymentRequestButton from "components/PaymentRequestButton"
import PayPalManager from "lib/PayPalManager"
import PropTypes from "prop-types"
import RecaptchaDisclosure from "components/Recaptcha/Disclosure"
import RecaptchaValidation from "components/Recaptcha/Validation"
import WalletProcessor from "lib/WalletProcessor"

const DonationForm = ({
  accountName,
  address,
  amount,
  bsbAccountNumber,
  cardNumber,
  city,
  config,
  country,
  cvv,
  creditCardProcessor,
  directDebitProcessor,
  email,
  errors,
  expiry,
  fieldsActive,
  firstName,
  frequency,
  giftAid,
  iban,
  lastName,
  loading,
  onChange,
  onSubmit,
  paymentMethod,
  payPalManager,
  postcode,
  recaptcha,
  state,
  submitActive,
  title,
  url,
  valid,
  walletProcessor,
}) => {
  const [paymentRequest, setPaymentRequest] = useState()
  useEffect(() => {
    if (walletProcessor.paymentRequest && walletProcessor.paymentRequest.canMakePayment) {
      walletProcessor.paymentRequest.canMakePayment().then((result) => {
        const presence = result ? "present" : "absent"
        Analytics.trackCheckoutStage(2, presence)
        if (result) {
          setPaymentRequest(walletProcessor.paymentRequest)
        }
      })
    }
  }, [walletProcessor.paymentRequest])

  const suggestedAmounts = config.suggestedAmounts[frequency]
  const paymentRequestButton = paymentRequest ? (
    <PaymentRequestButton
      options={{
        paymentRequest,
        style: { paymentRequestButton: { type: "donate" } },
      }}
      onClick={walletProcessor.handlePaymentRequestButtonClick}
    />
  ) : (
    ""
  )

  return (
    <ThemeProvider theme={donationsTheme}>
      <Loading show={loading} />

      <form
        acceptCharset="UTF-8"
        action={url}
        className="donation"
        id="new_donation"
        method="post"
        name="donation-form"
        noValidate="noValidate"
        onSubmit={onSubmit}
      >
        <FormError error={errors.payment_error} />
        <AmountDetails
          active={fieldsActive}
          amount={amount}
          currencySign={config.currencySign}
          errors={errors}
          frequency={frequency}
          initialFrequency={config.initialFrequency}
          onChange={onChange}
          defaultAmounts={config.defaultAmounts}
          suggestedAmounts={suggestedAmounts}
        />
        {config.showGiftAid && <GiftAidCheckbox checked={giftAid} onChange={onChange} />}
        {paymentRequestButton}
        <ContactDetails
          active={fieldsActive}
          email={email}
          errors={errors}
          firstName={firstName}
          lastName={lastName}
          onChange={onChange}
          title={title}
        />
        {config.showGiftAid && giftAid && (
          <AddressDetails
            active={fieldsActive}
            address={address}
            city={city}
            country={country}
            errors={errors}
            giftAid
            onChange={onChange}
            postcode={postcode}
            state={state}
          />
        )}
        <PaymentDetails
          accountName={accountName}
          amount={amount}
          bsbAccountNumber={bsbAccountNumber}
          cardNumber={cardNumber}
          cvv={cvv}
          creditCardProcessor={creditCardProcessor}
          directDebitProcessor={directDebitProcessor}
          errors={errors}
          expiry={expiry}
          frequency={frequency}
          fieldsActive={fieldsActive}
          iban={iban}
          locale={config.locale}
          onChange={onChange}
          paymentMethod={paymentMethod}
          payPalManager={payPalManager}
          submitActive={submitActive}
          valid={valid}
        />
        <RecaptchaValidation
          enabled={recaptcha.enabled}
          instance={recaptcha.instance}
          locale={config.locale}
          onError={recaptcha.onError}
          onVerify={recaptcha.onVerify}
          siteKey={recaptcha.siteKey}
        />
        <RecaptchaDisclosure enabled={recaptcha.enabled} />
      </form>
    </ThemeProvider>
  )
}

export const ConfigDefaultProps = {
  defaultAmounts: { once: "10", monthly: "10" },
  locale: "en",
  showGiftAid: false,
  suggestedAmounts: { once: ["10"], monthly: ["10"] },
}

DonationForm.defaultProps = {
  accountName: "",
  address: "",
  amount: undefined,
  bsbAccountNumber: false,
  cardNumber: false,
  config: ConfigDefaultProps,
  creditCardProcessor: new CreditCardProcessor({}),
  cvv: false,
  directDebitProcessor: new NullDirectDebitProcessor({}),
  email: undefined,
  errors: {},
  expiry: false,
  fieldsActive: true,
  firstName: undefined,
  frequency: "once",
  giftAid: false,
  iban: false,
  lastName: undefined,
  loading: false,
  onChange: () => {},
  onSubmit: () => {},
  paymentMethod: "credit_card",
  payPalManager: new PayPalManager({}),
  postcode: "",
  recaptcha: {
    enabled: false,
    instance: { current: null },
    onError: () => {},
    onVerify: () => {},
    siteKey: "site-key",
  },
  submitActive: true,
  title: undefined,
  url: undefined,
  valid: undefined,
  walletProcessor: new WalletProcessor({}),
}

DonationForm.propTypes = {
  accountName: PropTypes.string,
  address: PropTypes.string,
  amount: PropTypes.string,
  bsbAccountNumber: PropTypes.bool,
  cardNumber: PropTypes.bool,
  city: PropTypes.string,
  config: PropTypes.shape({
    currencySign: PropTypes.string,
    defaultAmounts: PropTypes.shape({
      once: PropTypes.string,
      monthly: PropTypes.string,
    }),
    initialFrequency: PropTypes.oneOf(["once", "monthly"]),
    locale: PropTypes.string,
    showGiftAid: PropTypes.bool,
    suggestedAmounts: PropTypes.shape({
      once: PropTypes.arrayOf(PropTypes.string),
      monthly: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  country: PropTypes.string,
  cvv: PropTypes.bool,
  creditCardProcessor: PropTypes.instanceOf(CreditCardProcessor),
  directDebitProcessor: PropTypes.instanceOf(DirectDebitProcessor),
  email: PropTypes.string,
  errors: errorsPropType,
  expiry: PropTypes.bool,
  fieldsActive: PropTypes.bool,
  firstName: PropTypes.string,
  frequency: PropTypes.oneOf(["once", "monthly"]),
  giftAid: PropTypes.bool,
  iban: PropTypes.bool,
  lastName: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  paymentMethod: paymentMethodPropType,
  payPalManager: PropTypes.instanceOf(PayPalManager),
  postcode: PropTypes.string,
  recaptcha: PropTypes.shape({
    enabled: PropTypes.bool,
    instance: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.elementType }),
    ]),
    onError: PropTypes.func,
    onVerify: PropTypes.func,
    siteKey: PropTypes.string,
  }),
  state: PropTypes.string,
  submitActive: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  valid: PropTypes.bool,
  walletProcessor: PropTypes.instanceOf(WalletProcessor),
}

export default DonationForm
