/**
 * Encapsulates the PayPal callbacks necessary to complete the up-to-date PayPal
 * payment process, which calls to PayPal to create an order in `createOrder()`
 * and then, in `onApprove()`, calls PayPal to capture the approved order, then
 * posts the details of the captured order along with the relevant donation
 * details to the TC-Donate backend
 */
export default class PayPalRecurringFlow {
  constructor({ payPalManager, tcDonateGateway }) {
    this.payPalManager = payPalManager
    this.tcDonateGateway = tcDonateGateway
    this.createSubscription = this.createSubscription.bind(this)
    this.onApprove = this.onApprove.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onError = this.onError.bind(this)
  }

  /**
   * A set of callbacks to add to the PayPalButton. In this case, the callbacks
   * that support the subscription process
   * @return {{createSubscription: {(*, *): *}, onApprove: {(*, *): *}}}
   * @see https://developer.paypal.com/docs/subscriptions/integrate/#4-create-a-subscription
   */
  get callbacks() {
    const { createSubscription, onApprove, onCancel, onError } = this
    return { createSubscription, onApprove, onCancel, onError }
  }

  /**
   * To be called when initiating the PayPal payment process, i.e. as a callback
   * createSubscription callback on the PayPalButton
   * @param data
   * @param actions an interface provided by the PayPal SDK into the actions it
   * provides
   * @return {*}
   */
  async createSubscription(data, actions) {
    const amount = this.payPalManager.amount
    const plansUrl = `paypal/plans/${this.payPalManager.regionCode}/${amount}`
    try {
      const plan = await this.tcDonateGateway.call(plansUrl, "GET", {}, "/api/v1")
      const agreementId = await actions.subscription.create({
        plan_id: plan.plan_id,
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })

      await this.payPalManager.postDonation({
        paypalAgreementId: agreementId,
      })

      return agreementId
    } catch (error) {
      // Handle errors resulting from tcDonateGateway fetching plans or
      // from postDonation submitting the donation.
      this.payPalManager.fail()
      return false
    }
  }

  /**
   * To be called when the donor has approved the payment process, i.e. as an
   * onApprove callback on the PayPalButton
   * @param data data on the approved subscription returned from PayPal
   * @return {*}
   */
  onApprove(data) {
    // Visually indicate that the page is loading. Used to encourage donors to
    // stay on the page after client-side payment is complete while they wait
    // for the donation to be submitted to the server.
    this.payPalManager.loading()

    return this.payPalManager.putDonation(data.subscriptionID, {
      paypalAgreementId: data.subscriptionID,
      paypalOrderId: data.orderID,
    })
  }

  onCancel() {
    this.payPalManager.cancelLoading()
  }

  onError() {
    this.payPalManager.cancelLoading()
  }
}
