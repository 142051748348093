// Packages the handling of interactions between the front end and our payment
// intent management API
export default class StripePaymentIntentManager {
  constructor({ tcDonateGateway }) {
    this.tcDonateGateway = tcDonateGateway
  }

  /**
   * Creates or updates a payment intent via the TC-Donate server
   * @param amount in large units, e.g dollars, not cents
   * @param paymentMethod as defined in the API docs - https://stripe.com/docs/api/payment_intents
   * @param recaptchaToken a token generated by reCAPTCHA on the form
   * @param recurring if true, request a payment intent that can be attached to
   *   a customer outside of this session - see
   *   https://stripe.com/docs/api/payment_intents/create#create_payment_intent-setup_future_usage
   * @return {*} A promise which resolves or rejects according to whether the
   *   payment intent operation was successful
   */
  save({ amount, paymentMethod, recaptchaToken, recurring }) {
    const amountInLocal = parseInt((parseFloat(amount) * 100).toFixed(0), 10)
    const paymentIntent = { amountInLocal, paymentMethod, recurring }
    return this.tcDonateGateway.call(
      "stripe/payment_intents",
      "POST",
      { recaptchaToken, paymentIntent },
      "/api/v1",
    )
  }
}
