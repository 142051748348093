import PropTypes from "prop-types"
import React from "react"
import Reaptcha from "reaptcha"
import NullRecaptcha from "./NullRecaptcha"

export default function Validation({ enabled, instance, locale, onError, onVerify, siteKey }) {
  if (!enabled) {
    return <NullRecaptcha ref={instance} onVerify={onVerify} />
  }

  return (
    <Reaptcha
      hl={locale}
      onError={onError}
      onVerify={onVerify}
      ref={instance}
      sitekey={siteKey}
      size="invisible"
    />
  )
}

Validation.propTypes = {
  enabled: PropTypes.bool.isRequired,
  instance: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]).isRequired,
  locale: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  siteKey: PropTypes.string.isRequired,
}
