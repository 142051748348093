/**
 * Encapsulates the PayPal callbacks necessary to complete the up-to-date PayPal
 * payment process, which calls to PayPal to create an order in `createOrder()`
 * and then, in `onApprove()`, calls PayPal to capture the approved order, then
 * posts the details of the captured order along with the relevant donation
 * details to the TC-Donate backend
 */
export default class PayPalOnceOffFlow {
  constructor({ payPalManager }) {
    this.payPalManager = payPalManager
    this.createOrder = this.createOrder.bind(this)
    this.onApprove = this.onApprove.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onError = this.onError.bind(this)
  }

  /**
   * A set of callbacks to add to the PayPalButton. In this case, the callbacks
   * that support the standard checkout process
   * @return {{createOrder: {(*, *): *}, onApprove: {(*, *): *}}}
   * @see https://developer.paypal.com/docs/checkout/integrate/#4-set-up-the-transaction
   */
  get callbacks() {
    const { createOrder, onApprove, onCancel, onError } = this
    return { createOrder, onApprove, onCancel, onError }
  }

  /**
   * To be called when initiating the PayPal payment process, i.e. as a callback
   * createOrder callback on the PayPalButton
   * @param data
   * @param actions an interface provided by the PayPal SDK into the actions it
   * provides
   * @return {*}
   */
  async createOrder(data, actions) {
    const value = this.payPalManager.amount
    const orderId = await actions.order.create({
      purchase_units: [
        {
          amount: { value },
        },
      ],
      intent: "capture",
    })

    try {
      await this.payPalManager.postDonation({
        paypalOrderId: orderId,
      })

      return orderId
    } catch (err) {
      return false
    }
  }

  /**
   * To be called when the donor has approved the payment process, i.e. as an
   * onApprove callback on the PayPalButton
   * @param data
   * @param actions an interface provided by the PayPal SDK into the actions it
   * provides
   * @return {*}
   */
  async onApprove(data, actions) {
    // Visually indicate that the page is loading. Used to encourage donors to
    // stay on the page after client-side payment is complete while they wait
    // for the donation to be submitted to the server.
    this.payPalManager.loading()

    const paymentData = await actions.order.capture()

    // Update payment details on server
    await this.payPalManager.putDonation(paymentData.id, {
      paypalOrderId: paymentData.id,
      paypalCaptureId: paymentData.purchase_units[0].payments.captures[0].id,
    })
  }

  onCancel() {
    this.payPalManager.cancelLoading()
  }

  onError() {
    this.payPalManager.cancelLoading()
  }
}
