import React from "react"
import PropTypes from "prop-types"
import Countries from "lib/Countries"
import i18n from "lib/i18n"
import { Dropdown } from "@theconversation/ui"
import { isEqual } from "underscore"

import styles from "./CountrySelect.styles.css"

const CountrySelect = ({
  active,
  defaultCountryCode,
  id,
  name,
  onBlur,
  onChange,
  priorityCountryCodes,
  value,
  helperText,
  error,
}) => {
  const countries = Countries()
  const countriesInEnglish = Countries("en")
  const codes = Object.keys(countries).sort((code1, code2) => {
    if (code1 === defaultCountryCode) {
      return -1
    } else if (code2 === defaultCountryCode) {
      return 1
    }
    const code1Priority = priorityCountryCodes.indexOf(code1) >= 0 ? 1 : 0
    const code2Priority = priorityCountryCodes.indexOf(code2) >= 0 ? 1 : 0
    if (code1Priority === code2Priority) {
      return [countries[code1], countries[code2]].sort()
    }
    return code2Priority - code1Priority
  })

  const codeLookup = ["", ...codes].reduce((obj, code) => {
    const countryName = code ? countries[code] : ""
    return Object.assign(obj, { [countryName]: code })
  }, {})

  const options = ["", ...codes].map((code) => {
    const text = code ? countries[code] : ""
    const val = code ? countriesInEnglish[code] : ""
    return (
      <option key={code} value={val}>
        {text}
      </option>
    )
  })

  const handleChange = (event) => {
    const country = event.target.value
    const countryCode = codeLookup[country]
    onChange({ countryCode, country })
  }

  return (
    <Dropdown
      disabled={!active}
      label={i18n.t("donation.label.country")}
      className={styles.dropdown}
      id={id}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      helperText={helperText}
      error={error}
      native
      fullWidth
    >
      {options}
    </Dropdown>
  )
}

CountrySelect.defaultProps = {
  active: true,
  defaultCountryCode: "AU",
  error: false,
  helperText: "",
  id: "country-select",
  name: "subscription[country]",
  onBlur: () => {},
  onChange: () => {},
  priorityCountryCodes: ["AU", "GB", "US", "FR", "NZ", "CA", "SE", "IT", "SG", "BE", "NL", "IE"],
  value: "Australia",
}

CountrySelect.propTypes = {
  active: PropTypes.bool,
  defaultCountryCode: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  priorityCountryCodes: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
}

export default React.memo(CountrySelect, (prevProps, nextProps) => isEqual(prevProps, nextProps))
