import React, { useEffect, useState } from "react"
import i18n from "lib/i18n"
import watchWindowWidth from "lib/watchWindowWidth"

import styles from "./styles.scss"

// &fm=jpg Format images in JPG to reduce filesizes
const defaultIMGIXOptions = "?ixlib=rb-1.1.0&q=45&auto=format&fm=jpg&fit=crop"

const IMGIXOptions = {
  desktop: `${defaultIMGIXOptions}&w=1600&h=500`, // width 1600px, height 500px
  mobile: `${defaultIMGIXOptions}&w=600&h=350`, // width 600px, height 350px
}

function HeroImage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => watchWindowWidth(setWindowWidth), [])

  const params = windowWidth >= 600 ? IMGIXOptions.desktop : IMGIXOptions.mobile
  const imageURL = i18n.t("hero_component.image_url") + params
  return <div style={{ backgroundImage: `url(${imageURL})` }} className={styles.heroImage} />
}

export default HeroImage
