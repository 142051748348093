import originalFetch from "unfetch"
import fetchRetry from "fetch-retry"
var fetch = fetchRetry(originalFetch)

/**
 * Encapsulates an error response from a call to `fetch()`. Based on the interface of `HTTPError` in the `ky` library.
 * @param {Object} response the response object from `fetch()`
 * @see https://github.com/sindresorhus/ky/blob/2049aff/source/errors/HTTPError.ts
 */
export class HTTPError extends Error {
  constructor(response) {
    const code = response.status || response.status === 0 ? response.status : ""
    const title = response.statusText || ""
    const status = `${code} ${title}`.trim()
    const reason = status ? `status code ${status}` : "an unknown error"

    super(`Request failed with ${reason}`)

    this.name = "HTTPError"
    this.response = response
  }
}

/**
 * Calls `fetch` with the given arguments, and returns the response body as JSON.
 * @param {String} url The URL to fetch
 * @param {String} method The HTTP method to use
 * @param {Object} headers The HTTP headers to use
 * @param {String} body The body to send
 * @throws {HTTPError} if the response is an HTTP error status
 * @returns {String} The JSON response body
 */
const doFetch = async (url, { method, headers, body }) => {
  const response = await fetch(url, {
    method,
    headers,
    body,
    retryOn: [503],
    retryDelay: (attempt) => Math.pow(2, attempt) * 1000, // exponential backoff - 1, 2 then 4 seconds
  })
  if (!response.ok) {
    throw new HTTPError(response)
  } else {
    const responseJson = await response.json()
    return responseJson
  }
}

export default doFetch
