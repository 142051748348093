// https://developers.google.com/analytics/devguides/collection/gtagjs/events
//
// Field     Type     Required  Usage
// category  text     yes       Typically the object that was interacted with (e.g. 'Donation Form')
// action    text     yes       The type of interaction (e.g. form field was "Skipped" or "Completed")
// label     text     no        Useful for categorizing events (e.g. form field label)
// value     integer  no        A numeric value associated with the event (e.g. custom donation amount)
//
// Examples
//
// Analytics.trackFormEvent("Donation Form", "Validation Error", "Email")
// Analytics.trackFormEvent("Donation Form", "Completed", "Custom Amount", 100)
// Analytics.trackFormEvent("Address Form", "Completed", "Postcode")
// Analytics.trackFormEvent("Address Form", "Skipped", "Home Address")
//
// As for when you may want to employ an event:
//  - On input "blur", it's either filled out or not ("Skipped"/"Completed")
//  - On form submit, there may be validation errors. Ideally emit an event
//    for each individual label
//
import { push } from "lib/GoogleTagManager/dataLayer"

/* global gtag */
const FORM_CATEGORIES = ["Donation Form", "Address Form"]

const FORM_ACTIONS = ["Completed", "Skipped", "Validation Error"]

const validCategory = (name) => FORM_CATEGORIES.includes(name)
const validAction = (action) => FORM_ACTIONS.includes(action)
const massageValueToInteger = (value) => {
  if (value === undefined) {
    return undefined
  }

  return Math.round(Number(value))
}

const track = (category, action, label, value) => {
  if (!category || !action) {
    // eslint-disable-next-line no-console
    console.warn("Category and action are required when tracking events")
  } else if (window.gtag && typeof window.gtag === "function") {
    gtag("event", action, {
      event_category: category,
      event_label: label,
      value: massageValueToInteger(value),
    })
  }
}

export default class Analytics {
  static trackFormEvent(category, action, label, value) {
    if (validCategory(category) && validAction(action)) {
      track(category, action, label, value)
      push({
        event: "form_event",
        form_name: category,
        field_action: action,
        field_label: label,
        field_value: massageValueToInteger(value),
      })
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Form category (${category}) or action (${action}) is invalid`)
    }
  }

  /**
   * Tracks a step in the checkout process
   * @param {Number} step the current sequential step number, starting at 1
   * @param {string} option enriches the step data with additional information
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
   */
  static trackCheckoutStage(step, option) {
    if (window.gtag && typeof window.gtag === "function") {
      gtag("event", "set_checkout_option", {
        checkout_step: step,
        checkout_option: option,
        non_interaction: true,
      })
    }
  }
}
