import Countries from "lib/Countries"
import ErrorTrack from "lib/ErrorTrack"
import StripeProcessor from "lib/StripeProcessor"

export default class WalletProcessor extends StripeProcessor {
  /**
   * Creates a `WalletProcessor`
   * @param stripe a reference to the Stripe JS SDK - https://stripe.com/docs/stripe-js
   * @param donationForm a React ref to the donation form
   * @param tcDonateGateway an instance of `TcDonateGateway`, used to call the TC-Donate backend
   * @param country the region's Stripe account's two letter ISO country code
   * @param currency the three letter ISO code for the region's currency, e.g. "aud"
   * @param label the label for the item to be paid for, e.g. "Donation"
   */
  constructor({ stripe, donationForm, tcDonateGateway, country, currency, label }) {
    super({ stripe, donationForm, tcDonateGateway })

    this.country = country
    this.currency = currency
    this.label = label

    this.processing = false

    this.handlePaymentRequestButtonClick = this.handlePaymentRequestButtonClick.bind(this)
    this.initiateWalletPayment = this.initiateWalletPayment.bind(this)
    this.showPaymentRequest = this.showPaymentRequest.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onPaymentMethod = this.onPaymentMethod.bind(this)
  }

  get available() {
    return !!(this.stripe && this.stripe.paymentRequest)
  }

  /**
   * Return a singleton payment request object (used to initiate payment
   * with Apple Pay or GPay.
   * @see https://stripe.com/docs/js/payment_request/create
   * @return {undefined|PaymentRequest}
   */
  get paymentRequest() {
    if (!this.available) {
      return undefined
    }
    if (!this.private_paymentRequest) {
      this.private_paymentRequest = this.buildPaymentRequest()
    }
    return this.private_paymentRequest
  }

  /**
   * Called when the payment request button is clicked. Initiates the wallet
   * payment process
   * @param event the click event
   */
  handlePaymentRequestButtonClick(event) {
    event.preventDefault()
    ErrorTrack.leaveBreadcrumb("WalletProcessor.handlePaymentRequestButtonClick()")
    if (this.processing) {
      ErrorTrack.leaveBreadcrumb(
        "WalletProcessor.handlePaymentRequestButtonClick() - already processing",
      )
    } else {
      this.processing = true
      this.donationForm.current.validatePayment("wallet", this.initiateWalletPayment)
    }
  }

  /**
   * Calls `initiateProcessing()` on the form to check validity and render form
   * inactive if we're valid, then shows the payment request
   */
  initiateWalletPayment() {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.initiateWalletPayment() (validation has passed)")
    this.donationForm.current.initiateProcessing(this.showPaymentRequest)
  }

  /**
   * Shows the payment interface with the correct amount and label
   */
  showPaymentRequest() {
    const { label } = this
    const amount = this.amountForPaymentRequest
    const total = { amount, label }
    ErrorTrack.leaveBreadcrumb("WalletProcessor.showPaymentRequest()", { amount, label })
    // Set the current amount before showing the payment interface
    this.paymentRequest.update({ total })
    // Show the relevant payment request handler interface (e.g. the Apple Pay shroud)
    this.paymentRequest.show()
  }

  /**
   * Called when cancelling a wallet payment. Runs the 'onCancel` handler of
   * the donation form (which makes the form active)
   */
  onCancel() {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.onCancel()")
    this.processing = false
    this.donationForm.current.onCancel()
  }

  /**
   * Called when the payment request button gets a payment method from Stripe.
   * We pass the payment method and donation details to the relevant payment
   * flow to execute, then fail or succeed accordingly
   * @param {PaymentResponse} event the "paymentmethod" event passed in from Stripe
   * @return {Promise<void>}
   * @see https://stripe.com/docs/js/payment_request/events/on_paymentmethod
   * @see https://stripe.com/docs/stripe-js/elements/payment-request-button?html-or-react=react#react-complete-payment
   * @see https://stripe.com/docs/js/appendix/payment_response
   */
  async onPaymentMethod(event) {
    const { complete, paymentMethod } = event
    try {
      // We know some donation details from the form at this point, but we need
      // to derive others (e.g. name and email) from the billing details which
      // have been provided with the payment method
      const donationDetails = {
        ...this.donationDetails,
        ...WalletProcessor.deriveDonationDetails(paymentMethod),
      }
      ErrorTrack.leaveBreadcrumb("WalletProcessor.onPaymentMethod()", {
        email: donationDetails.email,
        paymentMethod,
      })
      await this.paymentFlow.executePayment({ donationDetails, paymentMethod })
      // Tell the browser that payment has succeeded so it can close the payment
      // interface with a success message
      complete("success")
      this.succeed()
    } catch (error) {
      ErrorTrack.leaveBreadcrumb("WalletProcessor.onPaymentMethod() - failing", { error })
      // Tell the browser that payment has failed so it can close the payment
      // interface with a failure message
      complete("fail")
      this.fail(error)
    }
  }

  /**
   * Wraps a Stripe JS API call to confirmCardPayment(). Used by StripeOnceOffFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_card_payment
   * @param clientSecret
   * @return {*}
   */
  confirmOnceOff({ clientSecret }) {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.confirmOnceOff()")
    return this.stripe.confirmCardPayment(clientSecret)
  }

  /**
   * Wraps a Stripe JS API call to confirmCardPayment(). Used by StripeRecurringFlow
   * @see https://stripe.com/docs/js/payment_intents/confirm_card_payment
   * @param clientSecret
   * @return {*}
   */
  confirmRecurring({ clientSecret }) {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.confirmRecurring()")
    return this.stripe.confirmCardPayment(clientSecret)
  }

  /**
   * Internal interface to expose `donationForm`'s `succeed()` method, so we can
   *   call it if the payment process succeeds
   */
  succeed() {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.succeed()")
    this.processing = false
    this.donationForm.current.succeed(this.successUrl)
  }

  /**
   * Internal interface to expose `donationForm`'s `fail()` method, so we can
   *   call it if the payment process fails
   * @param error
   */
  fail(error) {
    ErrorTrack.leaveBreadcrumb("WalletProcessor.fail()", { error })
    this.processing = false
    this.donationForm.current.fail(error)
  }

  /**
   * Construct a Stripe payment request object (used to initiate payment
   * with Apple Pay or GPay), configuring `paymentmethod` and `cancel`
   * handlers.
   * @see https://stripe.com/docs/js/payment_request/create
   * @return {undefined|PaymentRequest}
   */
  buildPaymentRequest() {
    const paymentRequest = this.stripe.paymentRequest({
      country: this.country,
      currency: this.currency,
      total: {
        label: this.label,
        amount: 500, // Pick a safe default we expect to change
      },
      requestPayerEmail: true,
      requestPayerName: true,
    })

    // Listen for the creation of a payment method
    // - https://stripe.com/docs/js/payment_request/events/on_paymentmethod
    paymentRequest.on("paymentmethod", this.onPaymentMethod)
    paymentRequest.on("cancel", this.onCancel)

    return paymentRequest
  }

  /**
   * The current amount in the format expected by the Stripe PaymentRequest API
   * @returns the donation amount in cents, as an integer
   */
  get amountForPaymentRequest() {
    const { amount } = this.donationDetails
    return parseInt((parseFloat(amount) * 100).toFixed(0), 10)
  }

  /**
   * Unpacks the `billing_details` field in a Stripe Payment Method and
   * reformats the contact details found there into an object compatible with
   * the `donationDetails` property defined in this class. The resultant
   * object will be merged with known donation details and sent to our backend
   * with `submitForm()`.
   *
   * n.b. the name is parsed naively, splitting it on the first space. This
   * means that e.g input of { name: "BEN J MACLEOD" } is output as
   * { name: "BEN", lastName: "J MACLEOD" }
   * @param paymentMethod a Stripe payment method
   * @return {{country: *, lastName: string, address: *, city: *, name: string, postcode: *, state: *, email: *}}
   * @see https://stripe.com/docs/api/payment_methods/object#payment_method_object-billing_details
   */
  static deriveDonationDetails(paymentMethod) {
    const { address, email, name } = paymentMethod.billing_details
    let firstName = ""
    let lastName = ""
    if (name && name.match(/ /)) {
      ;[firstName, lastName] = name.split(/ +(.*)/)
    }
    firstName = firstName || name // If name had no spaces, assign it to firstName
    const { line1, city, state, country: countryCode, postal_code } = address || {}
    const country = Countries("en")[countryCode]
    return {
      address: line1,
      city,
      country,
      email,
      firstName,
      lastName,
      postcode: postal_code,
      state,
    }
  }
}
